<template>
  <div class="text-center" v-if="loading" style="padding-top: 50px">
    <loadingPage :msg='loadingMessage'/>
  </div>
  <div v-else class="">
    <div class="d-md-block d-lg-block">
      <Header />
    </div>

    <div class="wrapper">
      <div class="inner-wrapper">
        <div class="row">

          <!-- Policy Details -->
          <div class="policyname" v-if="selectedPlan && !this.editDisabled">
            <router-link to="/proposer-details" class="back-arrow py-3">
              <div class="d-flex align-items-center pt-4 pb-2 back_forward">
                <em class="icon-arrow-left me-3"></em>
                <h3 class="mb-0">Back</h3>
              </div>
            </router-link>
            <!-- <div class="policyname-text d-flex align-items-center justify-content-between mb-3">               -->
              <!-- <div class="d-flex align-items-center">
                <span class="me-3">
                  <img
                    :src="selectedPlan.supportingData.insCompanyLogo"
                    alt=""
                  />
                </span>
                <h6>{{ selectedPlan['insuranceAndProducts']['productName'] }} : {{ selectedPlan.productDetails.planOption.planName }}</h6>
              </div>
              <div class="d-flex justify-content-end flex-column">
                <label v-if="this.applicationNo != '' && this.applicationNo != null " class="appno">Application Number : {{this.applicationNo}}</label>
                <label v-if="this.uiQuoteId != '' && this.uiQuoteId != null " class="propno">Reference Number : {{this.uiQuoteId}}</label>
                <div class="d-flex justify-content-end" v-if="selectedPlan.other.biUrl">
                  <a :href="selectedPlan.other.biUrl" target="_blank">
                    <button class="btn btn-warning rounded-pill text-white p-1 px-3"> Benefit Illustration </button>
                  </a>
                </div>
              </div> -->
            <!-- </div> -->
            
          </div>
          <!-- END: Policy Details -->

          <div class="col-12 col-md-8 prop-dtls-chk-left-panel">
            <div class="pd-left">
              <div class="wizard-hold">
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="step6" role="tabpanel" aria-labelledby="step6-tab">
                    <div class="px-4">
                      <div class="floating-form"><br />
                        <div class="error-msg text-center" :innerHTML="errorMsgs">
                        </div><br />
                        <h3>You have completed all details</h3>
                        <h6 class="base-reg">Just review the information before payment</h6>
                        <div class="form-wrapper pt-3">
                          <div class="accordion" id="preview">
                            <div class="accordian-wrapper" v-for="(value, ind) in formInfo.fieldGroups" :key="ind">
                              <div class="accordion-item" v-if="ind != 'traceInfo' && ind != 'distributor'">
                                <h2 class="accordion-header" :id="'previewheading_' + ind" v-if="!value.visibility?true:checkVisibility(value.visibility)">
                                  <div class="accordion-button collapsed" data-bs-toggle="collapse"
                                    :data-bs-target="'#previewcollapse_' + ind" aria-expanded="false"
                                    :aria-controls="'previewcollapse_' + ind">
                                    <h4>{{ checkArray(value) ? value[0].label : value.label }}</h4>
                                    <div v-if="!this.editDisabled" class="edit fs-14 ms-auto" @click="editDetails(ind)"><em class="bi bi-pencil me-2"></em><span>Edit</span>
                                    </div>
                                  </div>
                                </h2>
                                <div :id="'previewcollapse_' + ind" :class="'accordion-collapse collapse ' + getShow(ind)"
                                  :aria-labelledby="'previewheading_' + ind" data-bs-parent="#preview" style="">
                                  <div class="accordion-body pdc-wrapper">
                                  <div v-if="checkArray(value)">
                                    <div v-for="(data, index) in value" :key="index">
                                      <h3>{{ data.relation }}</h3>
                                      <template class="" v-for="(value2, ind2) in data.fields" :key="ind2">
                                        <div class="mb-4" v-if="value2.type == 'static'">
                                          <div>
                                            <h6>{{ value2["label"] }}</h6>
                                          </div>
                                        </div>
                                        <div class="mb-4 col-md-12" v-if="checkArray(value2['input'])">
                                          <h6>{{ value2["label"] }}</h6>
                                          <b>{{ getInputValues(value2['input'], value2['type']) }}</b> 
                                        </div>
                                        <div v-else class="mb-4 address-text">
                                          <div v-if="value2.type == 'single-select'">
                                            <h6>{{ value2["label"] }}</h6>
                                            <b>{{ getSelectValue(value2.value, value2["input"]) }}</b>
                                          </div>
                                          <div v-else-if="value2.type == 'currency'">
                                            <h6>{{ value2["label"] }}</h6>
                                            <b>{{ this.currency(value2["input"]) }}</b>
                                          </div>
                                          <div v-else-if="value2.type == 'multi-select'">
                                            <h6>{{ value2["label"] }}</h6>
                                            <!-- <b>{{ getMultiSelectValue(value2.value, value2["input"]) }}</b> -->
                                            <b>{{ value2["input"].toString() }}</b>
                                          </div>
                                          <div v-else-if="value2.type == 'boolean'">
                                            <h6>{{ value2["label"] }}</h6>
                                            <b>{{ (value2["input"] == '1') ? 'Yes' : 'No' }}</b>
                                          </div>
                                          <div v-else-if="value2.type == 'date'">
                                            <h6>{{ value2["label"] }}</h6>
                                            <b>{{ dateconvert(value2["input"]) }}</b>
                                          </div>
                                          <div v-else-if="value2.type == 'file'">
                                            <h6>{{ value2["label"] }}</h6>
                                            <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal"
                                              data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fields[ind2].input"
                                              @click="previewFiles(formInfo.fieldGroups[ind].fields[ind2].input)">
                                              <img src="~@/assets/images/eye.png" alt="" />
                                            </button>
                                          </div>
                                          <div v-else-if="value2.type == 'checkbox'">
                                            <h6>
                                              <img v-if="value2.input" src="~@/assets/images/check-mark.svg"
                                                class="check-markimg" alt="" />
                                              {{ value2["label"] }}
                                            </h6>
                                            <!-- <b>{{ value2["input"] }}</b> -->
                                          </div>
                                          <div v-else>
                                            <h6>{{ value2["label"] }}</h6>
                                            <b>{{ value2["input"] }}</b>
                                          </div>
                                          <div v-if="value2.input == '0' && value2.type == 'number'">
                                            <!-- to handle case where input is zero and type number -->
                                            <h6>{{ value2["label"] }}</h6>
                                            <b>{{ value2["input"] }}</b>
                                          </div>
                                          <div v-if="value2.input == '0' && value2.type == 'currency'">
                                            <h6>{{ value2["label"] }}</h6>
                                            <b>{{ this.currency(value2["input"]) }}</b>
                                          </div>
                                        </div>
                                        
                                      </template>


                                      <template v-for="(value2, ind2) in data.fieldGroups" :key="ind2">
                                        <fieldset v-if="!value2.visibility ? true : checkVisibility(value2.visibility)">
                                          <!-- <legend>{{ convertSentenceCase(value2["name"]) }}</legend> -->
                                          <legend>{{ convertSentenceCase(value2["label"]) }} </legend>
                                          <div class="pro-details">
                                            <template class="" v-for="(value3, ind3) in value2.fields" :key="ind3">
                                              <div class="mb-4" v-if="value3.type == 'static'">
                                                <div>
                                                  <h6>{{ value3["label"] }}</h6>
                                                </div>
                                              </div>
                                              <div class="mb-4 col-md-12" v-if="checkArray(value3['input'])">
                                                <h6>{{ value3["label"] }}</h6>
                                                <b>{{ getInputValues(value3['input'], value3['type']) }}</b>
                                              </div>
                                              <div v-else class="mb-4 address-text">
                                                <div v-if="value3.type == 'single-select'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ getSelectValue(value3.value, value3["input"]) }}</b>
                                                </div>
                                                <div v-else-if="value3.type == 'currency'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ this.currency(value3["input"]) }}</b>
                                                </div>
                                                <div v-else-if="value3.type == 'multi-select'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <!-- <b>{{ getMultiSelectValue(value3.value, value3["input"]) }}</b> -->
                                                  <b>{{ value3["input"].toString() }}</b>
                                                </div>
                                                <div v-else-if="value3.type == 'boolean'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ (value3["input"] == '1') ? 'Yes' : 'No' }}</b>
                                                </div>
                                                <div v-else-if="value3.type == 'date' && value3.input">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ dateconvert(value3["input"]) }}</b>
                                                </div>
                                                <div v-else-if="value3.type == 'file'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <button type="button" class="btn btn-primary eyeicon-btn"
                                                    data-toggle="modal" data-target="#imageModal"
                                                    v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input"
                                                    @click="previewFiles(formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input)">
                                                    <img src="~@/assets/images/eye.png" alt="" />
                                                  </button>
                                                </div>
                                                <div v-else-if="value3.type == 'checkbox'">
                                                  <h6>
                                                    <img v-if="value3.input" src="~@/assets/images/check-mark.svg"
                                                      class="check-markimg" alt="" />
                                                    {{ value3["label"] }}
                                                  </h6>
                                                  <!-- <b>{{ value3["input"] }}</b> -->
                                                </div>
                                                <div v-else>
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ value3["input"] }}</b>
                                                </div>
                                                <div v-if="value3.input == '0' && value3.type == 'number'">
                                                  <!-- to handle case where input is zero and type number -->
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ value3["input"] }}</b>
                                                </div>
                                                <div v-if="value3.input == '0' && value3.type == 'currency'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ this.currency(value3["input"]) }}</b>
                                                </div>
                                              </div>
                                              
                                            </template>
                                          </div>
                                        </fieldset>
                                      </template>
                                    </div>
                                  </div>
                                  <div v-else class="">
                                    <div :class="(ind == 'healthQuestions')?'pro-details d-flex flex-column':'pro-details' ">
                                      <template class="" v-for="(value2, ind2) in value.fields" :key="ind2">
                                        
                                        <div class="mb-4" v-if="value2.type == 'static'">
                                          <div>
                                            <h6>{{ value2["label"] }}</h6>
                                          </div>
                                        </div>
                                        <template v-if="!value2.visibility ? true : checkVisibility(value2.visibility, ind)">
                                          <div class="mb-4 col-md-12" v-if="checkArray(value2['input'])">
                                            <h6>{{ value2["label"] }}</h6>
                                            <b>{{ getInputValues(value2['input'], value2['type']) }}</b>
                                          </div>
                                          <div v-else class="mb-4 address-text">
                                            <div v-if="value2.type == 'single-select'">
                                              <h6>{{ value2["label"] }}</h6>
                                              <b class="poiuy">{{ getSelectValue(value2.value, value2["input"]) }}</b>
                                            </div>
                                            <div v-else-if="value2.type == 'currency'">
                                              <h6>{{ value2["label"] }}</h6>
                                              <b>{{ this.currency(value2["input"]) }}</b>
                                            </div>
                                            <div v-else-if="value2.type == 'multi-select'">
                                              <h6>{{ value2["label"] }}</h6>
                                              <!-- <b>{{ getMultiSelectValue(value2.value, value2["input"]) }}</b> -->
                                              <b>{{ value2["input"].toString() }}</b>
                                            </div>
                                            <div v-else-if="value2.type == 'boolean'">
                                              <h6>{{ value2["label"] }}</h6>
                                              <b>{{ (value2["input"] == '1') ? 'Yes' : 'No' }}</b>
                                            </div>
                                            <div v-else-if="value2.type == 'date'">
                                              <h6>{{ value2["label"] }}</h6>
                                              <b>{{ dateconvert(value2["input"]) }}</b>
                                            </div>
                                            <div v-else-if="value2.type == 'file'">
                                              <h6>{{ value2["label"] }}</h6>
                                              <button type="button" class="btn btn-primary eyeicon-btn" data-toggle="modal"
                                                data-target="#imageModal" v-if="formInfo.fieldGroups[ind].fields[ind2].input"
                                                @click="previewFiles(formInfo.fieldGroups[ind].fields[ind2].input)">
                                                <img src="~@/assets/images/eye.png" alt="" />
                                              </button>
                                            </div>
                                            <div v-else-if="value2.type == 'checkbox'">
                                              <h6>
                                                <img v-if="value2.input" src="~@/assets/images/check-mark.svg"
                                                  class="check-markimg" alt="" />
                                                {{ value2["label"] }}
                                              </h6>
                                              <!-- <b>{{ value2["input"] }}</b> -->
                                            </div>
                                            <div v-else>
                                              <h6>{{ value2["label"] }}</h6>
                                              <b>{{ value2["input"] }}</b>
                                            </div>
                                            <div v-if="value2.input == '0' && value2.type == 'number'">
                                              <h6>{{ value2["label"] }}</h6>
                                              <b>{{ value2["input"] }}</b>
                                            </div>
                                            <div v-if="value2.input == '0' && value2.type == 'currency'">
                                              <h6>{{ value2["label"] }}</h6>
                                              <b>{{ this.currency(value2["input"]) }}</b>
                                            </div>
                                          </div>
                                        </template>
                                        
                                      </template>
                                    </div>


                                    <template v-for="(value2, ind2) in value.fieldGroups" :key="ind2">
                                      <fieldset v-if="!value2.visibility ? true : checkVisibility(value2.visibility)">
                                        <!-- <legend>{{ convertSentenceCase(value2["name"]) }}</legend> -->
                                        <legend>{{ convertSentenceCase(value2["label"]) }}</legend>
                                        <div class="pro-details">
                                          <template class="" v-for="(value3, ind3) in value2.fields" :key="ind3">
                                            <div v-if="!value3.visibility ? true : checkVisibility(value3.visibility)">
                                              <div class="mb-4" v-if="value3.type == 'static'">
                                                <div>
                                                  <h6>{{ value3["label"] }}</h6>
                                                </div>
                                              </div>
                                              <div class="mb-4 col-md-12" v-if="checkArray(value3['input'])">
                                                <h6>{{ value3["label"] }}</h6>
                                                <b>{{ getInputValues(value3['input'], value3['type']) }}</b>
                                              </div>
                                              <div v-else class="mb-4 address-text">
                                                <div v-if="value3.type == 'single-select'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ getSelectValue(value3.value, value3["input"]) }}</b>
                                                </div>
                                                <div v-else-if="value3.type == 'currency'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ this.currency(value3["input"]) }}</b>
                                                </div>
                                                <div v-else-if="value3.type == 'multi-select'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <!-- <b>{{ getMultiSelectValue(value3.value, value3["input"]) }}</b> -->
                                                  <b>{{ value3["input"].toString() }}</b>
                                                </div>
                                                <div v-else-if="value3.type == 'boolean'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ (value3["input"] == '1') ? 'Yes' : 'No' }}</b>
                                                </div>
                                                <div v-else-if="value3.type == 'date' && value3.input">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ dateconvert(value3["input"]) }}</b>
                                                </div>
                                                <div v-else-if="value3.type == 'file'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <button type="button" class="btn btn-primary eyeicon-btn"
                                                    data-toggle="modal" data-target="#imageModal"
                                                    v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input"
                                                    @click="previewFiles(formInfo.fieldGroups[ind].fieldGroups[ind2].fields[ind3].input)">
                                                    <img src="~@/assets/images/eye.png" alt="" />
                                                  </button>
                                                </div>
                                                <div v-else-if="value3.type == 'checkbox'">
                                                  <h6>
                                                    <img v-if="value3.input" src="~@/assets/images/check-mark.svg"
                                                      class="check-markimg" alt="" />
                                                    {{ value3["label"] }}
                                                  </h6>
                                                  <!-- <b>{{ value3["input"] }}</b> -->
                                                </div>
                                                <div v-else>
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ value3["input"] }}</b>
                                                </div>
                                                <div v-if="value3.input == '0' && value3.type == 'number'">
                                                  <!-- to handle case where input is zero and type number -->
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ value3["input"] }}</b>
                                                </div>
                                                <div v-if="value3.input == '0' && value3.type == 'currency'">
                                                  <h6>{{ value3["label"] }}</h6>
                                                  <b>{{ this.currency(value3["input"]) }}</b>
                                                </div>
                                              </div>
                                            </div>
                                          </template>
                                        </div>


                                        <!-- 333333 -->
                                        <template v-for="(value3, ind3) in value.fieldGroups[ind2].fieldGroups"
                                          :key="ind3">
                                          <fieldset v-if="!value3.visibility ? true : checkVisibility(value3.visibility)">
                                            <legend>{{ convertSentenceCase(value3["label"]) }}</legend>
                                            <div class="pro-details">
                                              <template class="" v-for="(value4, ind4) in value3.fields" :key="ind4">
                                                <div v-if="!value4.visibility ? true : checkVisibility(value4.visibility)">
                                                  <div class="mb-4" v-if="value4.type == 'static'">
                                                    <div>
                                                      <h6>{{ value4["label"] }}</h6>
                                                    </div>
                                                  </div>
                                                  <div class="mb-4 col-md-12" v-if="checkArray(value4['input'])">
                                                    <h6>{{ value4["label"] }}</h6>
                                                    <b>{{ getInputValues(value4['input'], value4['type']) }}</b>
                                                  </div>
                                                  <div v-else class="mb-4 address-text">
                                                    <div v-if="value4.type == 'single-select'">
                                                      <h6>{{ value4["label"] }}</h6>
                                                      <b>{{ getSelectValue(value4.value, value4["input"]) }}</b>
                                                    </div>
                                                    <div v-else-if="value4.type == 'currency'">
                                                      <h6>{{ value4["label"] }}</h6>
                                                      <b>{{ this.currency(value4["input"]) }}</b>
                                                    </div>
                                                    <div v-else-if="value4.type == 'multi-select'">
                                                      <h6>{{ value4["label"] }}</h6>
                                                      <!-- <b>{{ getMultiSelectValue(value4.value, value4["input"]) }}</b> -->
                                                      <b>{{ value4["input"].toString() }}</b>
                                                    </div>
                                                    <div v-else-if="value4.type == 'boolean'">
                                                      <h6>{{ value4["label"] }}</h6>
                                                      <b>{{ (value4["input"] == '1') ? 'Yes' : 'No' }}</b>
                                                    </div>
                                                    <div v-else-if="value4.type == 'date' && value4.input">
                                                      <h6>{{ value4["label"] }}</h6>
                                                      <b>{{ dateconvert(value4["input"]) }}</b>
                                                    </div>
                                                    <div v-else-if="value4.type == 'file'">
                                                      <h6>{{ value4["label"] }}</h6>
                                                      <button type="button" class="btn btn-primary eyeicon-btn"
                                                        data-toggle="modal" data-target="#imageModal"
                                                        v-if="formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input"
                                                        @click="previewFiles(formInfo.fieldGroups[ind].fieldGroups[ind2].fieldGroups[ind3].fields[ind4].input)">
                                                        <img src="~@/assets/images/eye.png" alt="" />
                                                      </button>
                                                    </div>
                                                    <div v-else-if="value4.type == 'checkbox'">
                                                      <h6>
                                                        <img v-if="value4.input" src="~@/assets/images/check-mark.svg"
                                                          class="check-markimg" alt="" />
                                                        {{ value4["label"] }}
                                                      </h6>
                                                      <!-- <b>{{ value4["input"] }}</b> -->
                                                    </div>
                                                    <div v-else>
                                                      <h6>{{ value4["label"] }}</h6>
                                                      <b>{{ value4["input"] }}</b>
                                                    </div>
                                                    <div v-if="value4.input == '0' && value4.type == 'number'">
                                                      <!-- to handle case where input is zero and type number -->
                                                      <h6>{{ value4["label"] }}</h6>
                                                      <b>{{ value4["input"] }}</b>
                                                    </div>
                                                    <div v-if="value4.input == '0' && value4.type == 'currency'">
                                                      <h6>{{ value4["label"] }}</h6>
                                                      <b>{{ this.currency(value4["input"]) }}</b>
                                                    </div>
                                                  </div>
                                                </div>
                                              </template>
                                            </div>
                                          </fieldset>
                                        </template>
                                        <!-- 333333 -->



                                      </fieldset>
                                    </template>
                                  </div>



                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="btn-hold pb-4 text-center mt-4">
                          <!-- <button type="button"
                            class="btn btn-outline-secondary rounded-pill mx-2"><em
                              class="bi bi-chevron-left me-2"></em><span>Previous</span>
                          </button> -->
                          <button type="button" class="btn btn-warning proceed-btn rounded-pill text-white mx-2" @click="buyPolicy()">
                            <span>Proceed</span>
                            <em class="bi bi-chevron-right ms-2"></em>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 prop-dtls-chk-right-panel">
            <div class="pd-right sticky-top" v-if="selectedPlan">
              <div class="pd-right-section1 shadow">
                <div class="poposer-header d-flex justify-content-between align-items-center">
                  <h3>Policy Details</h3>
                <div class="ref-num">
                <label v-if="this.applicationNo != '' && this.applicationNo != null " class="appno">Application Number : {{this.applicationNo}}</label>
                <!-- <label v-if="this.uiQuoteId != '' && this.uiQuoteId != null " class="propno">CRN : {{this.uiQuoteId}}</label> -->
                <div class="d-flex justify-content-end" v-if="selectedPlan.other.biUrl">
                  <a :href="selectedPlan.other.biUrl" target="_blank">
                    <button class="btn btn-warning rounded-pill text-white p-1 px-3"> Benefit Illustration </button>
                  </a>
                </div>
              </div>
                </div>
                <div class="policy-dts-wrap">
                  <div class="policy-name">
                    <figure>
                      <img :src="selectedPlan?.supportingData?.insCompanyLogo" alt="image" />
                    </figure>
                    <h6 class="mb-0 me-auto">
                      <h6>{{ selectedPlan['insuranceAndProducts']['productName'] }} <br/> {{ selectedPlan.productDetails.planOption.planName }}</h6>
                    </h6>
                    <a :href="selectedPlan?.supportingData?.Messages?.brochureLink" target="_blank" class="custom-tooltip">
                      <em class="download-new"></em>
                      <span class="tooltip-content">Download Brochure</span>
                    </a>
                  </div>
                  <div class="p-details">
                    <ul class="policy-item-detail1 mt-4">
                      <li>
                        <h6>Sum Insured</h6>
                        <span><em class="rupee">&#x20B9;</em> {{
                          convertInLakh(
                            selectedPlan?.individualDetails[0]?.sumInsured
                          )
                        }}</span>
                      </li>
                      <li>
                        <h6>Members Insured</h6>
                        {{ noOfAdult }} {{ (noOfAdult > 1) ? 'Adults' : 'Adult' }}
                        <span v-if="noOfChild > 0">  &amp; {{ noOfChild }} {{ (noOfChild > 1) ? 'Children' : 'Child'}}</span>
                      </li>
                      <li>
                        <h6>Policy Tenure</h6>
                        <span>
                          <!--{{ selectedPlan?.productDetails?.policyTermUnit }}-->
                          {{
                              selectedPlan.productDetails
                                .premiumForEachPolicyTerm[selectedPolicyIndex]
                                .policyTerm
                          }}
                          Year
                        </span>
                      </li>
                      <!-- <li>
                        <h6>Payment Frequency</h6>
                        <span>{{ selectedPlan?.productDetails?.policyTermUnit }}
                          Yearly</span>
                      </li> -->
                      <li>
                        <h6>Net Premium</h6>
                        <span>&#8377;
                          <!-- {{
                            currency(
                              selectedPlan.productDetails
                                .premiumForEachPolicyTerm[selectedPolicyIndex]
                                .premiumForEachPPO[0].premiumForEachPPF[0]
                                .premiumValue
                            )
                          }} -->
                          {{
                            currency(
                              selectedPlan.totalPremiumDetails[selectedPolicyIndex]
                                .totalPremiumValue
                            )
                          }}
                        </span>
                      </li>
                      <!-- <li>
                          <h6>Addons</h6>
                          <span>1,430</span>
                      </li> -->
                      <li>
                        <h6>GST @ 18%</h6>
                        <span>&#8377;
                          <!-- {{
                            currency(
                              selectedPlan.productDetails
                                .premiumForEachPolicyTerm[selectedPolicyIndex]
                                .premiumForEachPPO[0].premiumForEachPPF[0]
                                .totalTax
                            )
                          }} -->
                          {{
                            currency(
                              selectedPlan.totalPremiumDetails[selectedPolicyIndex]
                                .totalTax
                            )
                          }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <ul class="ttl-preminum poposer-footer">
                  <li><b>Total Premium</b></li>
                  <span>&#8377;
                    <!-- {{
                      currency(
                        selectedPlan.productDetails.premiumForEachPolicyTerm[
                          selectedPolicyIndex
                        ].premiumForEachPPO[0].premiumForEachPPF[0]
                          .totalPremiumValue
                      )
                    }} -->
                    {{
                      currency(
                        selectedPlan.totalPremiumDetails[selectedPolicyIndex]
                          .totalPremiumWithTax
                      )
                    }}
                  </span>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--row-->

        <!-- Session Expiry Alert -->
        <div v-if="editProposalFlag" id="editProposalAlert" class="custom-warning-alert modal fade show">
          <div class="modal-backdrop fade show"></div>
          <div class="modal-dialog modal-dialog-centered premium-modal">
            <div class="modal-content">
              <div class="modal-header">
                <div class="icon-box">
                  <i class="bi bi-exclamation-lg"></i>
                </div>
                <h5 class="modal-title text-center pt-4 px-5">Edit Proposal Warning!</h5>
                <!-- <button type="button" @click="closeAlertDialog()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
              </div>
              <div class="modal-body">
                <p><span class="p-2">Do you really want to edit these details?</span></p>
              </div>
              <div class="modal-footer justify-content-center">
                <button type="button" class="btn text-white btn-sm" @click="editProposalCancel()" style="width:100px" >Cancel</button>
                <button type="button" class="btn text-white btn-sm" @click="editProposalOk()" style="width:100px" >Ok</button>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Session Expiry Alert -->
      </div>
    </div>
  </div>
</template>
  
<script>
import Header from "@/components/Header.vue";
import loadingPage from "@/components/loading3.vue";
import moment from 'moment';
//import { getPaySettings } from '@/mixins/common';
import $ from "jquery";
export default {
  name: "ProposerDetailsCheckpage",
  components: {
    Header,
    loadingPage,
  },
  mixins: [],
  mounted() {

    sessionStorage.setItem("proposal_review_flag", JSON.stringify(1));
    console.log('store data: ', this.$store.state.configData);
    this.configData = this.$store.state.configData;

    this.channel_type = JSON.parse(sessionStorage.getItem("channel_type"));

    this.requestJson = JSON.parse(sessionStorage.getItem("request_json"));
    this.selectedPlan = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    this.proposalUiId = JSON.parse(sessionStorage.getItem("proposal_ui_id"));
    
    console.log("this.selectedPlan: ", this.selectedPlan);
    /* if (sessionStorage.getItem('uiQuoteId')) {
      this.uiQuoteId = JSON.parse(sessionStorage.getItem('uiQuoteId'));
    } */
    // DP Relaetd
    let sessionData = sessionStorage.getItem("userProfile");
    if (sessionData != null) {
      this.userData = JSON.parse(sessionData);
      this.selectedMembersList = this.userData.memberList;
      console.log("this.userData: ", this.userData.memberList);
    }

    this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));

    this.selectedPolicyIndex = this.getSelectedPolicyIndex(
      this.requestJson.product.policyTerm
    );

    this.formInfo = JSON.parse(sessionStorage.getItem("filledData"));
    this.proposalJson = this.formInfo;

    if (!this.formInfo) {
      // Redirecction Logic

    } else {
      //console.log(this.formInfo)
      console.log('this.form info iii: ', this.formInfo);

      //let containerObject = new JSONObject(this.formInfo);
      //has method
      // if (this.formInfo.fieldGroups.otpDetails) {
      //   //get Value of video
      //   console.log('otp present');
      // } else {
      //   console.log('otp not present');
      // }
    }

    this.noOfAdult = 0;
    this.noOfChild = 0;
    for (let i = 0; i < this.selectedPlan.individualDetails.length; i++) {
      if (this.selectedPlan.individualDetails[i].insuredRelWithProposer == 'Son' || this.selectedPlan.individualDetails[i].insuredRelWithProposer == 'Daughter') {
        this.noOfChild++;
      } else {
        this.noOfAdult++;
      }
    }
    const status = sessionStorage.getItem('arkaJourneyStatus') ? sessionStorage.getItem('arkaJourneyStatus') : "";
    if(status === "Proposal Error"){
      this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check-page', msg: "Proposal Error" }});
    } else if (status === 'Awaiting Client Approval') {
      this.editDisabled = true;
    } else {
      this.editDisabled = false;
    }
    if(sessionStorage.getItem('isArka')){
      this.isArka = true;
    } else {
      this.isArka = false;
    }

  },
  props: {},
  data() {
    return {
      configData: "",
      loading: false,
      channel_type: "",
      noOfAdult: 0,
      noOfChild: 0,
      requestJson: "",
      selectedPlan: "",
      proposalJson: "",
      formInfo: {},
      errorCounter: 0,
      userData: "",
      selectedMembersList: [],
      proposalUiId: 0,
      rmData: "",
      filledData: "",
      proposalUiId: "",
      channel_type: "",
      editProposalFlag: false,
      tabIndexToEdit: "",
      editDisabled: false,
      isArka: false,
      loadingMessage: "",
      errorMsgs: "",
    };
  },
  methods: {
    getInputValues(data, type) {
      let str = "";
      data.forEach((element, index) => {
        let tmp1 = element.memberSequenceNumber;
        let tmp2;
        if(type == 'boolean') {
          if(element.answer == '1') {
            tmp2 = 'Yes';
          } else if(element.answer == '0') {
            tmp2 = 'No';
          } else {
            tmp2 = '';
          }
        } else {
          tmp2 = element.answer;
        }

        if(index > 0 && data[index-1]['answer'] && data[index]['answer']) {
          str+= ', '
        } 
        if(tmp2) {
          str+= this.formInfo.fieldGroups.insuredMembers[index].relation +' : '+tmp2;
        }
      });
      return str;
    },
    async buyPolicy() {
      if (this.isArka) {
        const journey_id = this.$store.state.journeyId;
        const journeyStatus = await this.getJourneyStatus(journey_id);
        if (journeyStatus === "Proposal Application Pending") {
          const reqData = {
            journey_id,
          }
          const response = await this.backendApi('validationForm', reqData, {});
          let errorMessage = "";
          if (response?.data?.errors?.length > 0) {
            let ck = 1;
            response.data.errors.forEach(function (element) {
              errorMessage += ck + '. ' + element.errorDisplayMessage + '<br/>';
              ck++;
            });
            this.errorMsgs = errorMessage;
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            return;
          }
          if (response.data.data === "validated") {
            this.updateProposalStatus(11);
          }
        } else {
          const reqBody = {
            journey_id,
            redirect_url: new URL(location).origin,
          }
          this.loading = true;
          const respData = await this.backendApi("saveProposal", reqBody, {});
          const journeyStatus = await this.getJourneyStatus(journey_id);
          if(journeyStatus === "Proposal Submission Initiated"){
            this.reqTimeOut();
            await this.getProposalPoll();
          }
        }
      } else {
        //this.convertProposalJson();
        this.channel_type = JSON.parse(sessionStorage.getItem("channel_type"));
        if (this.channel_type == "B2B") // B2B case
        {
          console.log('inside b2b');
          this.updateProposalStatus(11);
          // this.sendMailToCustomer();   
        }
        else // B2C case
        {
          console.log('B TO C');
          var resumeLink = new URL(location).origin + "/#/proposal-details-check-diy?journeyId=" + this.$store.state.journeyId + '&transactionId=' + this.rmData['transaction_id'];
          window.location.href = resumeLink;

        }
      }
    },
    async getJourneyStatus(journey_id) {
      const reqData = {
        journey_id,
      }
      const resp = await this.backendApi('getJourneyStatus', reqData, {}, "get");
      return resp.data.data.journeyStatus;
    },
    async getProposalPoll() {
      const maxDuration = 3 * 60 * 1000; // Three minutes in milliseconds

      console.log('getProposalPoll called');
      const param = [this.$store.state.journeyId];
      const timeoutDuration = 3 * 60 * 1000;
      const startTime = new Date().getTime();

      this.loading = true;
      this.loadingMessage = "Please wait, we are still processing the request";

      const resp = await this.backendApi('getProposalPoll', param, {}, "get");

      console.log('poll response : ', resp)

      const elapsedTime = new Date().getTime() - startTime;

      if (elapsedTime > maxDuration) {
        console.log('Max duration reached. Exiting poll.');
        this.loading = false;
        return;
      }

      if (resp.data.data.journeyStatus === "Awaiting Client Approval") {
        await new Promise(resolve => setTimeout(resolve, 5000));
        await this.getProposalPoll();
      } else if (resp?.data?.data?.proposal_response?.message === "Please wait, we are still processing the request") {
        console.log('Please wait, we are still processing the request: Block executed');
        await new Promise(resolve => setTimeout(resolve, 5000));
        await this.getProposalPoll();
      } else if (resp?.data?.data?.proposal_response?.status?.code === "200") {
        const journey_id = this.$store.state.journeyId;
        const reqData = {
          journey_id,
        }
        const journeyResp = await this.backendApi('getJourneyStatus', reqData, {}, "get");
        sessionStorage.setItem('arkaJourneyStatus', journeyResp.data.data.journeyStatus);
        sessionStorage.setItem('applicationNumber', journeyResp.data.data.applicationNo);
        this.loading = false;
        this.$router.push('/thankyou');
        console.log('Proposal status updated: ', resp.data);
        return;
      } else if(resp?.data?.data?.proposal_response?.errors?.length > 0){
        this.loading = false;
        this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check-page', msg: resp?.data?.data?.proposal_response?.errors[0]?.errorDisplayMessage }});
        return;
      }else {
        console.log('Unexpected status code:', resp.status);
        await new Promise(resolve => setTimeout(resolve, 5000));
        await this.getProposalPoll();
      }
    },

    reqTimeOut() {
      this.timer = setTimeout(() => {
      if(this.loading === true){
        this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check-page', msg: "Error fetching polling." }});
      }
      }, 180000);
    },

    sendMailToCustomer()
    {
      this.loading = true;
      console.log('sendMailToCustomer: called')
      console.log(new URL(location).origin+"/#/proposal-details-check-diy");
      let rmData = JSON.parse(sessionStorage.getItem("rm_data"));
      console.log(rmData);
      var resumeLink= new URL(location).origin+"/#/proposal-details-check-diy?proposalId="+this.proposalUiId+'&transactionId='+rmData['transaction_id'];
      console.log("printResumeLink",resumeLink);
      
        let qData = {
                      
                        "distributor": {
                          "distributorID": this.requestJson.distributor.distributorID,
                          "channelType": this.channel_type,
                        },
                        //"distributorID": "BCIBL",
                        "eventName": "reviewAndPayHealth",
                        "data": [
                          {
                              "resumeLink": resumeLink,
                              "mobileNumber": this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['mobileNumber']['input'],
                              "channel": "sms"
                          },
                          {
                              "resumeLink": resumeLink,
                              "fullName": this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['firstName']['input'] + " " + this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['lastName']['input'],
                              "productName": this.selectedPlan["insuranceAndProducts"]["productName"],
                              "emailId": [this.formInfo['fieldGroups']['proposerDetails']['fieldGroups']['personalDetails']['fields']['email']['input']],
                              "channel": "email"
                          }
                        ]
                      
                    }
        var headers = {
          // "quoteId":this.uiQuoteId,
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        this.backendApi("sendEmailandSMS", qData, headers)
          .then((response) => {
            //console.log('save quote response: ', response);
            this.loading = false;
            if(response == 'error') {
              self.apiError = true;
            // let msg=['We are facing some issue while sending an e-mail']

            // this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check', msg: msg }});
              // alert("Error occurred while sending Mail. Please try again!");
            }
            else if(response.status.error=="error")
            {
              let msg=['We are facing some issue while sending an e-mail']

              this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check', msg: msg }});
            }
            else if(response && response.data) {
              console.log('inside thank you page redirection block');
              this.$router.push('/thankyoumail');
              //window.location.href = new URL(location).origin
              
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
          let msg=['We are facing some issue from backend while sending an e-mail']
            this.$router.push({ path: '/networkalert', query: {backUrl:'proposal-details-check', msg: msg }});
          });
    },
    updateProposalStatus(statusid)
    {
      this.loading = true;
        let qData = {
          "journey_id": this.$store.state.journeyId,
          "status_id": statusid
          };
        var headers = {
          // "quoteId":this.uiQuoteId,
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        
        this.backendApi("saveProposal", qData, headers)
          .then((response) => {
            this.loading = false;
            if(response == 'error') {
              self.apiError = true;
              // let msg=['We are facing some issue while saving proposal status']
              // this.$router.push({ path: '/alert-diy', query: {msg: msg }});
            }
            
             else if(response && response.data) {
              this.$router.push('/thankyoumail');
              console.log('proposal status updated: ', response.data)
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log('error msg block email', error);
            // let msg=['We are facing some issue from backend while saving proposal status']
            //   this.$router.push({ path: '/alert-diy', query: {msg: msg }});
          });
    },
    isAreaSelected(val) {
      if (val == "proposerDetails") {
        //return "true";
      }
    },
    checkArray(data) {
      return Array.isArray(data);
    },
    getShow(tab) {
      if (tab == 'proposerDetails') {
        return "show";
      } else {
        return '';
      }
    },
    activePage(val) {
      if (val == "proposerDetails") {
        return "active";
      } else {
        //return "disabled";
        return "";
      }
    },
    convertSentenceCase(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
    isTabContentActive(val) {
      if (val == "proposerDetails") {
        return "show active";
      } else {
        //return "disabled";
        return "";
      }
    },
    getSelectValue(array, val) {
      if (array) {
        let itemIndex = array.findIndex(item => item.Value == val);
        if (array[itemIndex]) {
          return array[itemIndex]['Text'];
        }
      } else {
        return '';
      }
    },
    dateconvert(value) {
      const formattedDate = moment(value).format('DD/MM/YYYY')
      // console.log(formattedDate)
      return formattedDate;
    },
    getColSize(val, ind1, ind2) {
      if (ind1 == "healthQuestions") {
        return "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12";
      } else if (val == "boolean") {
        return "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12";
      } else {
        return "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4";
      }
    },
    currency(amt) {
      // const tmpAmt = parseFloat(amt).toFixed(2);
      // return new Intl.NumberFormat().format(tmpAmt);
      const tmpAmt = parseFloat(amt).toFixed(Math.max(((amt+'').split(".")[1]||"").length, 2));
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getDescriptionText(str) {
      let descText = "";
      if (str) {
        var check = str.includes("#");
        if (check) {
          let tempArr = str.split("#");
          descText = tempArr[0];
        } else {
          descText = str;
        }
      } else {
        descText = "";
      }
      return descText;
    },
    getVisitibilityExp(exp, index) {
      console.log("getVisitibilityExp called: ", exp, index);
      let ind = "'" + index + "'";
      let result = exp.replace(/index/g, ind);
      //console.log('final index: ', ind);
      //console.log('final index result: ', result);
      return result;
    },
    getVisitibilityExpIndexZero(exp) {
      let length = this.proposalJson.fieldGroups.insuredMembers.length;
      let tempResult = "";
      for (var i = 0; i < length; i++) {
        if (i > 0) {
          tempResult += " || (" + exp.replace(/index/g, i) + ")";
        } else {
          tempResult = "(" + exp.replace(/index/g, i) + ")";
        }
      }
      return tempResult;
    },
    checkVisibility(value, ind="") {
      //console.log("check Visibility called: ", value);
      // default expression evalutiona added along with visibility
      if (value) {
        let obj;
        if(ind == 'healthQuestions') {
          let length = this.formInfo?.fieldGroups?.insuredMembers?.length;
          let tempResult = "";
          for (var i = 0; i < length; i++) {
            if (i > 0) {
              tempResult += " || (" + value.replace(/index/g, i) + ")";
            } else {
              tempResult = "(" + value.replace(/index/g, i) + ")";
            }
          }
          console.log('inside healthQuestions: ppp ', tempResult);
          obj = this.getFinalObject(tempResult);
        } else {
          obj = this.getFinalObject(value);
        }
        
        try {
          return eval(obj);
        } catch (err) {
          console.log("error exp: ", value);
        }
      } else {
        return true;
      }
    },
    getDescriptionTextArray(str) {
      let tempArr = [];
      if (str) {
        var check = str.includes("#");
        if (check) {
          tempArr = str.split("#");
        } else {
          tempArr[0] = str;
          tempArr[1] = str;
        }
      } else {
        tempArr[0] = "";
        tempArr[1] = "";
      }

      return tempArr;
    },
    getDescriptionText(str) {
      let descText = "";
      if (str) {
        var check = str.includes("#");
        if (check) {
          let tempArr = str.split("#");
          descText = tempArr[0];
        } else {
          descText = str;
        }
      } else {
        descText = "";
      }
      return descText;
    },
    checkValidation(value, label = "") {
      //console.log('check Validation called: ', value);
      if (value) {
        let obj = this.getFinalObject(value);
        try {
          return eval(obj);
        } catch (err) {
          console.log("error exp 2: ", value);
          return true;
        }
      } else {
        //return false;
      }
    },
    getFinalObject(tempResponse) {
      //console.log('tempResponse: ', tempResponse)
      var tempString = JSON.stringify(tempResponse);
      var updatedString = tempString.replace(
        /'input' in formInfo\[/g,
        "formInfo["
      );
      var updatedString2 = updatedString.replace(
        /formInfo\[/g,
        "this.formInfo["
      );
      //var updatedString3 = updatedString2.replace(/this.this./g, "this.");
      //console.log('final String: ', updatedString3);
      return JSON.parse(updatedString2);
    },
    getMemberWiseInput(healthQues) {
      console.log("getMemberWiseInput: ", healthQues);
      var tempString = JSON.stringify(healthQues);
      //console.log('hhhhhhhhhhhhhhhh: ', tempString);membersInputJson
      //var updatedString = tempString.replace(/"input":""/g, '"input":[{"memberSequenceNumber": "1","answer": "0"},{"memberSequenceNumber": "2","answer": "0"},{"memberSequenceNumber": "3","answer": "0"}]');
      var updatedString = tempString.replace(
        /"input":""/g,
        '"input":' + JSON.stringify(this.membersInputJson)
      );
      //console.log('getMemberWiseInput: updated', JSON.parse(updatedString));
      return JSON.parse(updatedString);
    },
    activeNextTab(currentTab) {
      console.log("active next tab called", this.tabsArr);
      let ckk = this.checkVisibility(
        this.formInfo["fieldGroups"][currentTab]["visibility"]
      );
      if (ckk == true) {
        // Setting Up Filled Tab on localstorage
        /* localStorage.setItem("filledDataTab", JSON.stringify(currentTab));
                sessionStorage.setItem("filledDataTab", JSON.stringify(currentTab));
 
                localStorage.setItem("filledData", JSON.stringify(this.formInfo));
                sessionStorage.setItem("filledData", JSON.stringify(this.formInfo)); */
      }

      var tabIndex = this.tabsArr.indexOf(currentTab);
      let nextTab;
      //console.log('tab index: ', tabIndex)
      if (tabIndex < this.tabsArr.length - 1) {
        nextTab = this.tabsArr[tabIndex + 1];
        //console.log('next tab: ', nextTab);
      } else {
        // If conntrol is on last tab
        console.log("inside: conntrol is on last tab");
        //this.$router.push('/proposaldetailscheck');
      }
      if (nextTab) {
        this.activeTab(nextTab);
      }
    },
    activeTab(tabToActive) {
      console.log("ativeTab Called", tabToActive);

      // Scroll Top
      //this.scrollToTop();

      console.log(
        "vb logic: ",
        this.formInfo["fieldGroups"][tabToActive]["visibility"]
      );
      let ckk = this.checkVisibility(
        this.formInfo["fieldGroups"][tabToActive]["visibility"]
      );
      if (ckk == true) {
        //console.log('this.tabsArr', this.tabsArr)
        this.currentTab = tabToActive;
        sessionStorage.setItem("currentTab", JSON.stringify(this.currentTab));

        for (var i = 0; i < this.tabsArr.length; i++) {
          var tab = this.tabsArr[i];
          if ($("#step" + tab + "-tab").hasClass("active")) {
            console.log("inside tab active");
            $("#step" + tab + "-tab").removeClass("active");
            $("#step" + tab + "-tab").attr("aria-selected", "false");
            //$("#step" + tab + '-tab').addClass("disabled");
            $("#step" + tab).removeClass("show active");
          }
        }
        if ($("#step" + tabToActive + "-tab").hasClass("disabled")) {
          $("#step" + tabToActive + "-tab").removeClass("disabled");
        }
        $("#step" + tabToActive + "-tab").addClass("active");
        $("#step" + tabToActive).addClass("show active");
        $("#step" + tabToActive + "-tab").attr("aria-selected", "true");
      } else {
        this.activeNextTab(tabToActive);
      }
    },
    goBack() {
      this.currentTab = JSON.parse(sessionStorage.getItem("currentTab"));
      console.log("current tab: ", this.currentTab);
      var tabIndex = this.tabsArr.indexOf(this.currentTab);
      this.activePrevTab(this.currentTab);
    },
    activePrevTab(tabToActive) {
      console.log("active prev tab called", this.tabsArr);
      var tabIndex = this.tabsArr.indexOf(tabToActive);
      if (tabIndex > 0) {
        console.log("inside prev tab true");
        let prevTab = this.tabsArr[tabIndex - 1];
        //console.log('uuu: ', this.formInfo['fieldGroups'][prevTab]['visibility']);
        let ckk = this.checkVisibility(
          this.formInfo["fieldGroups"][prevTab]["visibility"]
        );
        if (ckk == true) {
          this.activeTab(prevTab);
        } else {
          this.activePrevTab(prevTab);
        }
      } else {
        this.$router.push("/checkout");
      }
    },
    convertInLakh(amt) {
      if (amt < 10000000) {
        return amt / 100000 + " Lac";
      } else {
        return amt / 10000000 + " Cr.";
      }
    },
    getSelectedPremium(pTerm) {
      let itemIndex =
        this.selectedPlan.productDetails.premiumForEachPolicyTerm.findIndex(
          (item) => item.policyTerm == pTerm
        );
      if (itemIndex > -1) {
        return this.selectedPlan.productDetails.premiumForEachPolicyTerm[
          itemIndex
        ];
      }
    },
    getSelectedPolicyIndex(pTerm) {
      let itemIndex = this.selectedPlan.totalPremiumDetails.findIndex(item => item.policyTerm == pTerm);
      if (itemIndex > -1) {
        return itemIndex;
      }
    },
    checkPattern(value, pattern) {
      try {
        return true;
        return eval(eval(pattern).test(value));
      } catch {
        console.log("Invalid regular expression: ", pattern);
        return true;
      }
    },
    editDetails(tabIndex) {
      this.editProposalFlag = true;
      this.tabIndexToEdit = tabIndex;
    },
    editProposalCancel() {
      this.editProposalFlag = false;
    },
    editProposalOk(){
      console.log('edit inside');
      self.editProposalFlag = false;
      sessionStorage.setItem("filledDataTab", JSON.stringify(this.tabIndexToEdit));
      this.$router.push('/proposer-details');
    },
    closeAlertDialog() {
      this.editProposalFlag = false;
    }
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
  