<template>
    <div>
       
        <GateCriteria />
    </div>
</template>

<script>

import GateCriteria from "@/components/GateCriteria.vue";

export default {
    name:' Gate-Criteria',
    components: {
       
        GateCriteria
    },
}
</script>
