<template>
  <div>
    <div class="container">
        <div class="failed-img-holder text-center mt-5 mb-3">
          <figure>
            <img src="~@/assets/images/payment_failed.png" alt="" />
          </figure>
          <br>
          <figcaption class="mb-4">

            <span id="errorText">Sorry, something went wrong, Please try again after sometime.</span><br>
            <!-- <span id="errorText">We are facing with following error.</span><br> -->
            
            <div v-if="this.msgError">
            <span id="errorMsgHeading">{{this.msgError}}.</span>
            </div>
             <div v-if="this.reqId">
            <span id="errorMsg"><b>Request Id: </b>{{this.reqId}}.</span>
            </div>
            <div v-if="this.msg.length=='1'">
            <span  id="errorMsg">{{this.msg[0]}}.</span>
            </div>
            
            <div v-if="this.msg.length>'1'">
             <span v-if="!flag" id="errorMsg">{{this.msg[0]}}....<button id="moreBtn" v-on:click="moreError">more</button></span>
            <span v-if="flag" id="errorMsg">{{this.msg[0]}}....<button id="moreBtn" v-on:click="moreError">less</button></span>
            </div>
            <div v-if="flag">
              <div v-for="(error,index) in this.msg" :key="error">
                <span v-if="index!='0'" id="errorMsg">{{error}}.</span>
              </div>
            </div>
            
            <button v-if="this.backUrl=='logout'" v-on:click="logout" id="btn">Relogin</button>
            <button v-else v-on:click="goBack" id="btn">Go back</button>
            
          </figcaption>
        </div>
    </div>
  </div>
</template>


<script>
import { getConsumer } from '../../mixins/common';
export default {
  name: "networkalert",
  props: {  },
  components: {},
  data() {
    return {
        msg:[],
        msgError:"",
        backUrl:"",
        flag:false,
        reqId:"",
        rmData:""
    };
  },
  
  mounted() {
      //  sessionStorage.setItem("quote_request_checkout_flag", JSON.stringify(1));

    let m=this.$route.query.msg
    let back=this.$route.query.backUrl
    let e=this.$route.query.msgError
    this.msgError=e
        let r=this.$route.query.reqId
    this.reqId=r
    console.log("error",this.msgError)
    this.backUrl=back
        if(typeof(m)=="string")
    this.msg.push(m)
    else if(m!=undefined)
    this.msg=m
    
    if(sessionStorage.getItem("rm_data"))
    {
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"))
    }
   
   
    
    // this.paymentResponse();
    //this.getPayment()
  
  },
  methods: {
     logout()
    {
            this.loadingMessage = "Redirecting to login page"
            this.loading=true
            sessionStorage.clear();
            localStorage.clear() 
            if(this.rmData['RoleId']=='1')
            {
              window.location.href = getConsumer()['loginUrlB2C'];
            }
            else
            {
              window.location.href = getConsumer()['loginUrlB2B'];
            }
               
    },
    goBack()
    { console.log('iiii ', this.backUrl)
        if(this.backUrl=="multi-quote")
        this.$router.push({path: '/multi-quote'})
        else if(this.backUrl=="plandetails-page")
        this.$router.push({path: '/plan-details'})
        else if(this.backUrl=="addonrider-page")
        this.$router.push({path: '/addon-rider'})
        else if(this.backUrl=="proposal-page") {
        this.$router.push({path: '/proposer-details'}) }
        else if(this.backUrl=="termplanqte")
        this.$router.push({path: '/multi-quote'})
        else if(this.backUrl=="proposal-details-check")
        this.$router.push({path: '/proposal'})
        else if(this.backUrl=="dashboard")
        this.$router.push({path:'/dashboard'})
        else if(this.backUrl=="proposal-details-check-page")
        this.$router.push({path:'/proposal-details-check'})
        else 
        this.$router.push({path: '/'+this.backUrl});
    }
  },
};
</script>

<style scoped lang="scss">

#errorMsgHeading{
  font-size: 17px;
}
#errorText{
    font-size: 20px;
}
#errorMsg{
    font-size: 15px;
    font-weight: 400;
    text-align: left;
}
#moreBtn{
    color: blue;
    cursor: pointer;
    border: none;
    background-color: white;
}
#btn{
    // width:70px;
    height:30px;

    font-size: 15px;
    border:solid 1px rgb(245, 94, 94);
    border-radius: 4px;
    background-color: rgb(245, 94, 94)
}
// @import "~@/assets/scss/components/paymentsuccess.scss";
// @import "~@/assets/scss/components/paymentfailed.scss";
</style>