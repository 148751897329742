<template>
  <div class="home">
    <LandingPage />
  </div>
</template>

<script>
// @ is an alias to /src
import LandingPage from "@/components/LangingPage.vue";

export default {
  name: "LandingView",
  components: {
    LandingPage,
  },
};
</script>
