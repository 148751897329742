<template>
 <div class="loading_block_outer" v-if="this.load">
      
    <loadingPage/>
    <div class="snippet" data-title=".dot-spin">
      <div class="stage">
        <div class="dot-spin"></div>
      </div>
    </div>
  </div>
  <div v-else class="landing">
    <Header @clicked-rm-details="captureRmData"/>
    <div class="main-container">
    <div class="left-div">

      <div class="img-box">
        <img src="../assets/images/family-img.png" alt="image">
      </div>

      <div class="customer-no">
        <button type="button" class="btn btn-primary px-3 py-2 d-flex align-items-center"><em
            class="icon sm icon-phone me-2"></em><span>18204587282</span></button>
            <div class="text"><label>*</label><p>All discounts are provided by insurers as per IRDAI approved insurance plans.</p></div>
      </div>
    </div>
    <div class="right-div">
      <div class="landingcard">
        <div class="text-center">
          <div class="text-center text-light bg-danger mb-3" v-if="!rmData">RM data not available, please login first!</div>
          <h1 class="mb-3 d-none d-sm-none d-md-block d-lg-block">Buy Health Insurance Plans Online</h1>
          <h3 class="d-none d-sm-none d-md-block d-lg-block">Get upto <span class="percentage">10%</span> Online Discount*</h3>
        </div>
        <Wizard/>
      </div>
      <div class="disterm-cnt" v-if="this.term && this.disclaimer">
        <ul class="foot-link">
        <li v-if="this.term" class="">
          <a href="#" class="">Terms &amp; Conditions</a>
        </li>
        <li v-if="this.disclaimer" class="">
          <a href="#" class="">Disclaimer</a>
        </li>
      </ul>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
import Wizard from "@/components/wizard.vue";
import Header from "@/components/Header.vue";
import loadingPage from "@/components/loading.vue";
export default {
  name: 'LandingPage',
  components: {
    Wizard,
    Header,
    loadingPage
  },
  data(){
    return{
       rmData:"",
       load:false,
       term:"",
       disclaimer:""
    }
  },
  props: {

  },
  mounted()
  {
    this.removeSessionData();
    let rmId = this.$route.query.id
    if(rmId!=undefined)
      this.load=true
    else
      this.load=false
    if (sessionStorage.getItem("rm_data")) {
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"))
    }
    else
    {
      this.rmData=""
    }
  },
  methods:{
    captureRmData(val){
      //  this.rmData=val
      //  console.log("this.rmData",this.rmData) request_json
    },
    removeSessionData() {
      // Removing data when clicked on 1SB Logo : PFM-8086 (point no. 6)
      if(sessionStorage.getItem('request_json')) {
        sessionStorage.removeItem('request_json')
      }
      if(sessionStorage.getItem('selected_plan_data')) {
        sessionStorage.removeItem('selected_plan_data')
      }
      if(sessionStorage.getItem('kyc_response')) {
        sessionStorage.removeItem('kyc_response')
      }
     /*  if(sessionStorage.getItem('uiQuoteId')) {
        sessionStorage.removeItem('uiQuoteId')
      } */
      if(sessionStorage.getItem('pincode_data')) {
        sessionStorage.removeItem('pincode_data')
      }
      if(sessionStorage.getItem('quote_response_id')) {
        sessionStorage.removeItem('quote_response_id')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .main-container{ 
    width: 100%; display: flex; 
    .left-div{
      position: fixed;
      height: 100vh;
    }
  }
  .right-div{
    margin-left: auto;
  }
</style>
