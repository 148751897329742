<template>
    <div>
       
        <dashboard />
    </div>
</template>

<script>
// @ is an alias to /src

import dashboard from "@/components/dashboard.vue";

export default {
  name: 'dashboardpage',
  components: {
 
    dashboard
  }
}
</script>

<style scoped lang="scss">

</style>


  