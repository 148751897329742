<template>
 <div class="text-center" v-if="loading" style="padding-top: 50px;">
    <loadingPage />
  </div>
  <div v-else class="">
    <div class="d-none d-sm-none d-md-block d-lg-block">	
    <Header />
    </div>

    <div class="cp-holder">
      <router-link to="/multi-quote">
        <div class="d-flex align-items-center pb-4 back_forward">	
          <em class="icon-arrow-left me-3"></em>
          <h3 class="mb-0">Back</h3>
        </div>
      </router-link>

      <div class="cp-heading">
        <h3>Comparison of Plans</h3>
      </div>

      <div class="cp-plan-details">
        <div class="cp-col-holder">
          <div class="cp-col cp-company-dtls">
            <figure></figure>
            <span class="cp-amt-number">
              <h6>Plan Name</h6>
            </span>
            <span class="cp-amt-number">
              <h6>Cover</h6>
            </span>
            <span class="cp-amt-number">
              <h6>Premium</h6>
            </span>
          </div>
          <div class="cp-col cp-company-dtls" v-for="(data, ind) in selectedPlans" :key="ind">
            <figure>
              <img :src="data.supportingData?.insCompanyLogo" alt="" />
            </figure>
            <h5>
              {{
                data.insuranceAndProducts.productName +
                " : " +
                data.productDetails.planOption.planName
              }}
            </h5>
            <span class="cp-amt-number">&#8377;
              {{ convertInLakh(data.individualDetails[0].sumInsured) }}</span>
            
              <button type="button" class="btn btn-warning text-white" @click="selectPlan(data)">

                <small>
                    &#8377;
                    {{
                        currency(
                        data.productDetails.premiumForEachPolicyTerm[0]
                            .premiumForEachPPO[0].premiumForEachPPF[0].totalPremiumValue
                        )
                    }}
                    / year
                </small>

            </button>
          </div>
        </div>
      
        <div class="checkbox-grp">
            
          <div class="form-check me-3">
            <input class="form-check-input" type="radio" name="test1" v-on:click="filetrCompare(1)" id="cpchk1" />
            <label class="form-check-label fs-12 font-reg" for="cpchk1">
              Hide common features
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="test1" v-on:click="filetrCompare(2)" id="cpchk2" />
            <label class="form-check-label fs-12 font-reg" for="cpchk2">
              Highlight differences
            </label>
          </div>
          <div class="form-check">
            <button class="btn btn-warning text-white btn-sm" v-on:click="filetrCompare(0)">Reset</button>
          </div>
        </div>

        <div class="compare-wrapper mb-3" v-for="(dt, ind) in comparePlanData.section" :key="ind">
          <div class="mid-heading">{{ dt.sectionName }}</div>

          <div :class="'cp-col-holder cp-main-dtls ' + getCompareClasses(dt, ind2)" v-for="(dt2, ind2) in dt.section[0].section" :key="ind2">
            <div class="cp-col">
              <h6>{{ dt2?.name }}</h6>
            </div>
            <div class="cp-col highlighted-row word-break">
              <h6 v-if="dt2?.info[0]?.shortData">
                {{ dt2?.info[0]?.shortData }}
              </h6>
              <img v-else
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaklEQVRIia3WvW8URxjH8Q+2iYSNLDv22br1YVwkDQWCDipAouEv4L2gQzSkSAESSZMmRVCkFFQUNJQUiBaJCvEO0rUIkGWdb0ens8TpOCsiyVGs17qcb71ruF+38/zm+e7MPPOya3l5WUHtxyHMYxwf8Q6v0S6SYCwnPoWrOI8fMzyf8Qi3cH+7ZLsyRja6AbmO2Zwf6tUT/ITng4IjA9q+x0Pc3CEIjuLxBjAXNiOZkuM7hPRqDH/it+1go7iHg98A6tUNXMyC/YxjQwKluoV9/bASfhkyCPbi937YFUwMclerVSsrK9tmrNVqqtVqVvi0jdGlsAtZzqmpKc1mU71eHxgPIWg0GiYnJ7NSjOJsClvCD1nOxcVF8/PzQghbgHEcq9frSqWSpaWlrBRwkqRMc6uvXC7rdrtCCJvfIQRxHJudnbWwsJCX4nAKi/KcEEWJLYRgfX1dq9VSKpWKgEgOh5ExfFfEnQI7nY5Wq2V8fLwoKNWeEXwq6g4haLfbJiYmdDodcRwX7foPPo3hbRF3HMeba1SpVKyurorjWLfbVS6X87q/I1mz15JrYneWs7cYKpUK/r+GyAM+Iyn9tuTwHaharbZZ3ikoVRRF5ubmhBDyNv6DFAa3s1zNZtP09HRmMURRZGZmxtraWlaKRgpLL88RvDG8E79XV/FXCoH/cBn/Dhn0QnLy64WRXOnXhghq4Iyk7LfA4A8DbtivBJ3C+97GQW+QX3HJDjZ7n17iCF71BwbB4A4O4K7i6xgkxXBU34hSZT3lerWIczgheaTObbR/xgc8lZT2A/y9XaIisC19JC/iHU/zF6tgv97O5wayAAAAAElFTkSuQmCC"
                alt="image" class="cp-table-img" />
            </div>

            <div class="cp-col highlighted-row word-break">
              <h6 v-if="dt?.section[1]?.section[ind2]?.info[0]?.shortData">
                {{ dt?.section[1]?.section[ind2]?.info[0]?.shortData }}
              </h6>
              <img v-else
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaklEQVRIia3WvW8URxjH8Q+2iYSNLDv22br1YVwkDQWCDipAouEv4L2gQzSkSAESSZMmRVCkFFQUNJQUiBaJCvEO0rUIkGWdb0ens8TpOCsiyVGs17qcb71ruF+38/zm+e7MPPOya3l5WUHtxyHMYxwf8Q6v0S6SYCwnPoWrOI8fMzyf8Qi3cH+7ZLsyRja6AbmO2Zwf6tUT/ITng4IjA9q+x0Pc3CEIjuLxBjAXNiOZkuM7hPRqDH/it+1go7iHg98A6tUNXMyC/YxjQwKluoV9/bASfhkyCPbi937YFUwMclerVSsrK9tmrNVqqtVqVvi0jdGlsAtZzqmpKc1mU71eHxgPIWg0GiYnJ7NSjOJsClvCD1nOxcVF8/PzQghbgHEcq9frSqWSpaWlrBRwkqRMc6uvXC7rdrtCCJvfIQRxHJudnbWwsJCX4nAKi/KcEEWJLYRgfX1dq9VSKpWKgEgOh5ExfFfEnQI7nY5Wq2V8fLwoKNWeEXwq6g4haLfbJiYmdDodcRwX7foPPo3hbRF3HMeba1SpVKyurorjWLfbVS6X87q/I1mz15JrYneWs7cYKpUK/r+GyAM+Iyn9tuTwHaharbZZ3ikoVRRF5ubmhBDyNv6DFAa3s1zNZtP09HRmMURRZGZmxtraWlaKRgpLL88RvDG8E79XV/FXCoH/cBn/Dhn0QnLy64WRXOnXhghq4Iyk7LfA4A8DbtivBJ3C+97GQW+QX3HJDjZ7n17iCF71BwbB4A4O4K7i6xgkxXBU34hSZT3lerWIczgheaTObbR/xgc8lZT2A/y9XaIisC19JC/iHU/zF6tgv97O5wayAAAAAElFTkSuQmCC"
                alt="image" class="cp-table-img" />
            </div>

            <div class="cp-col highlighted-row word-break" v-if="dt?.section[2]?.section[ind2]">
              <h6 v-if="dt?.section[2]?.section[ind2]?.info[0]?.shortData">
                {{ dt?.section[2]?.section[ind2]?.info[0]?.shortData }}
              </h6>
              <img v-else
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaklEQVRIia3WvW8URxjH8Q+2iYSNLDv22br1YVwkDQWCDipAouEv4L2gQzSkSAESSZMmRVCkFFQUNJQUiBaJCvEO0rUIkGWdb0ens8TpOCsiyVGs17qcb71ruF+38/zm+e7MPPOya3l5WUHtxyHMYxwf8Q6v0S6SYCwnPoWrOI8fMzyf8Qi3cH+7ZLsyRja6AbmO2Zwf6tUT/ITng4IjA9q+x0Pc3CEIjuLxBjAXNiOZkuM7hPRqDH/it+1go7iHg98A6tUNXMyC/YxjQwKluoV9/bASfhkyCPbi937YFUwMclerVSsrK9tmrNVqqtVqVvi0jdGlsAtZzqmpKc1mU71eHxgPIWg0GiYnJ7NSjOJsClvCD1nOxcVF8/PzQghbgHEcq9frSqWSpaWlrBRwkqRMc6uvXC7rdrtCCJvfIQRxHJudnbWwsJCX4nAKi/KcEEWJLYRgfX1dq9VSKpWKgEgOh5ExfFfEnQI7nY5Wq2V8fLwoKNWeEXwq6g4haLfbJiYmdDodcRwX7foPPo3hbRF3HMeba1SpVKyurorjWLfbVS6X87q/I1mz15JrYneWs7cYKpUK/r+GyAM+Iyn9tuTwHaharbZZ3ikoVRRF5ubmhBDyNv6DFAa3s1zNZtP09HRmMURRZGZmxtraWlaKRgpLL88RvDG8E79XV/FXCoH/cBn/Dhn0QnLy64WRXOnXhghq4Iyk7LfA4A8DbtivBJ3C+97GQW+QX3HJDjZ7n17iCF71BwbB4A4O4K7i6xgkxXBU34hSZT3lerWIczgheaTObbR/xgc8lZT2A/y9XaIisC19JC/iHU/zF6tgv97O5wayAAAAAElFTkSuQmCC"
                alt="image" class="cp-table-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import loadingPage from "@/components/loading.vue";
import $ from "jquery";
export default {
  name: "ComparePlan",
  components: {
    Header,
    loadingPage
  },
  props: {},
  mounted() {
    if(sessionStorage.getItem("selectedForFilter")) {
      this.selectedPlans = JSON.parse(sessionStorage.getItem("selectedForFilter"));
      let compareRequest = { "productInfo": [] };

      for(var i = 0; i < this.selectedPlans.length; i++) {
        let tmpReq = {
          "manufacturerId": this.selectedPlans[i].insuranceAndProducts.insuranceCompanyCode,
          "productId": this.selectedPlans[i].insuranceAndProducts.productCode,
          "planId": this.selectedPlans[i].productDetails.planOption.planId
        };

        compareRequest.productInfo.push(tmpReq);
      }

      console.log('final compare request: ', compareRequest);
      
      this.getCpmparePlan(compareRequest);

      

    } else {
      this.$router.push("/");
    }
    
    console.log("selectedForFilter: ", this.selectedPlans);

    
  },
  data() {
    return {
      loading: false,
      isCoverfor: false,
      isRecommended: false,
      selectedPlans: "",
      comparePlanData: "",
      compareFilter: 0,
    };
  },
  methods: {
    convertInLakh(amt) {
      if (amt < 10000000) {
        return amt / 100000 + " Lacs";
      } else {
        return amt / 10000000 + " Crore";
      }
    },
    currency(amt) {
      // const tmpAmt = parseFloat(amt).toFixed(2);
      // return new Intl.NumberFormat().format(tmpAmt);
      const tmpAmt = parseFloat(amt).toFixed(Math.max(((amt+'').split(".")[1]||"").length, 2));
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    selectPlan(selectedData) {
      // Save selected plan
      sessionStorage.setItem(
        "selected_plan_data",
        JSON.stringify(selectedData)
      );
      this.$router.push("/addon-rider");
    },
    getCpmparePlan(qData) {
      //console.log('save quote called');
      var self = this;
      var headers = {};
      let qData2 = qData;
      self.loading = true;
      this.backendApi("getComparePlan", qData2, headers)
        .then((response) => {
          self.loading = false;
          if (response == 'error') {
            self.apiError = true;
          }
          else if (response.status == "error") {
            let msg = ['We are facing some issue while getting compare plan data']

            this.$router.push({ path: '/networkalert', query: { backUrl: 'multi-quote', msg: msg } });
          }
          else if (response && response.data) {

            console.log('compare response: ', response.data);
            this.comparePlanData = response.data.data;
           
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
          let msg = ['We are facing some issue from backend while getting compare plan data ']
          this.$router.push({ path: '/networkalert', query: { backUrl: 'multi-quote', msg: msg } });
        });
    },
    filetrCompare(value) {
        this.compareFilter = value;
        console.log("filetrCompare called: ", this.compareFilter);
        if(this.compareFilter == 1) {
            $(".common-features").addClass("d-none");
            if ($(".high-diff").hasClass("my-text-red")) {
                $(".high-diff").removeClass("my-text-red");
            }
        } else if(this.compareFilter == 2) {
            if ($(".common-features").hasClass("d-none")) {
                $(".common-features").removeClass("d-none");
            }

            $(".high-diff").addClass("my-text-red");
        } else {
            $("#cpchk1").prop('checked', false);
            $("#cpchk2").prop('checked', false);
            if ($(".high-diff").hasClass("my-text-red")) {
                $(".high-diff").removeClass("my-text-red");
            }
            if ($(".common-features").hasClass("d-none")) {
                $(".common-features").removeClass("d-none");
            }
        }
    },
    getCompareClasses(dt, ind2) {
        console.log('dt: ', dt, 'ind2: ', ind2);
        console.log('section length: ', dt.section.length);

        if(dt.section.length == 2) { 
            if(dt?.section[0]?.section[ind2]?.info[0]?.shortData && dt?.section[1]?.section[ind2]?.info[0]?.shortData) {
                return "common-features";
            } else {
                return "high-diff";
            }
        } else {
            if(dt?.section[0]?.section[ind2]?.info[0]?.shortData && dt?.section[1]?.section[ind2]?.info[0]?.shortData && dt?.section[2]?.section[ind2]?.info[0]?.shortData) {
                return "common-features";
            } else {
                return "high-diff";
            }
        }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
