  <template>
    <div class="loading_block_outer">
    <div class="loading_block text-center">
      <div>
        <figure>
          <img src="~@/assets/images/loading_img.jpg" alt="" />
        </figure>
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <p>{{msg}}</p>
            </div>
          </div>
        </div>
        <div>
         <img src="~@/assets/images/loading_gif.svg" alt="" />
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "loading3",
    props: {
      msg: String,
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <!-- <style scoped lang="scss">

  </style> -->
  