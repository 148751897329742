<template>
  <header class="header">
    <div class="hold">
      <a href="">
        <div v-if="disabledLink()" @click="logout()" class="logo">
          <img >
        </div>
        <div v-if="!disabledLink()" @click="removeResumeData()" class="logo">
          <img >
        </div>
      </a>

      <div class="right ms-auto">
        <router-link v-if="!disabledLink()" to="/dashboard" class="dashbrd d-flex justify-content-center align-items-center">
        <button type="button" class="btn btn-light rounded-pill me-3 icon-cta">
          <img
            src="~@/assets/images/icons8-dashboard-64.png"
            alt=""
            class="dashboard-img"
          />
          <span class="tooltip-label">Dashboard</span>
        </button>       
      </router-link>
      <button v-if="disabledLink()" @click="logout()" type="button" class="btn btn-light rounded-pill me-3 dashbrd d-flex justify-content-center align-items-center">Dashboard</button>
        <div class="dropdown">
          <button class="btn dropdown-toggle btn-sm rounded-pill" type="button" id="dropdownMenuButton1"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="../assets/images/user-dropdown.png" alt="img">
          </button>
          <ul class="dropdown-menu drop" aria-labelledby="dropdownMenuButton1">
            <li class=" ms-2 align-items-center d-flex"><img src="~@/assets/images/icon1.png" alt=""/><a class="dropdown-item">{{ rmData.Name }}</a></li>
            <li class="ms-2 align-items-center d-flex"><img src="~@/assets/images/icon2.png" alt=""/><a class="dropdown-item">{{ rmData.Mobile }}</a></li>
            <li class="ms-2 align-items-center d-flex"><img src="~@/assets/images/icon3.png" alt=""/><a class="dropdown-item">{{ rmData.Email }}</a></li>
            <li class="ms-2 align-items-center d-flex"><img src="~@/assets/images/left-arrow-grey.png" alt=""/><span class="dropdown-item cursor-pointer" @click="logout">Logout</span></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  <!-- Session Expiry Alert -->
  <div v-if="tokenWarningFlag" id="tokenWarningAlert" class="custom-warning-alert modal fade show">
    <div class="modal-backdrop fade show"></div>
    <div class="modal-dialog modal-dialog-centered premium-modal">
      <div class="modal-content">
        <div class="modal-header">
          <div class="icon-box">
            <i class="bi bi-exclamation-lg"></i>
          </div>
          <h5 class="modal-title text-center pt-4 px-5">Session Expiry Warning!</h5>
          <!-- <button type="button" @click="closeAlertDialog()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body">
          <p><span class="p-2">Your session is about expire. Please click Ok to continue</span></p>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn text-white btn-sm" @click="tokenExpireWarningCancel = true" style="width:100px" >Cancel</button>
          <button type="button" class="btn text-white btn-sm" @click="tokenExpireWarning = true" style="width:100px" >Ok</button>
        </div>  
      </div>
    </div>
  </div>
  <!-- ./Session Expiry Alert -->
</template>

<script>
//import Wizard from "@/components/wizard.vue";
import {getLogoutURL, getConsumer} from '@/mixins/common';
import $ from "jquery";
import { Buffer } from 'buffer';
export default {
  name: 'Header',
  components: {

  },
  props: {

  },
  data()
  {
    return{
      rmData: "",
      loading: false,
      tokenWarningFlag: false,
      tokenExpireWarning: false,
      tokenExpireWarningCancel: false,
      intervalTime: null,
    }
  },
  unmounted() {
    clearInterval(this.intervalTime); // - Clears Interval
  },
  async mounted() {
    this.deleteAllCookies();
    const token = this.$route.query.token;
    const journeyId = this.$route.query.journeyId;
    const isArka = JSON.parse(sessionStorage.getItem('isArka'));
    if (token && journeyId) {
      await sessionStorage.clear();
      await localStorage.clear();
      this.$store.commit('setToken', token);
      this.$store.commit('setJourneyId', journeyId);
      sessionStorage.setItem('isArka', JSON.stringify(true));
      const newLocation = location.origin + "/#/proposer-details";
      location.href = newLocation;
      window.location.reload();
    } else if(!isArka) {
      let rmId = this.$route.query.id;
      let diy = this.$route.query.diy ? this.$route.query.diy : false;
      if (sessionStorage.getItem("diy") == null) {
        sessionStorage.setItem("diy", JSON.stringify(diy))
      }
      // if (this.$store.state.token && sessionStorage.getItem("rm_data")) {
      //   this.rmData = JSON.parse(sessionStorage.getItem("rm_data"))
      // } else {
      //   if(rmId){
      //     this.startSession(rmId)
      //   }
      // }
      if (this.$store.state.token && sessionStorage.getItem("rm_data")) {
        this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
        if (rmId && rmId == this.rmData["transaction_id"]) {
          if (getConsumer()["consumerId"] == "KVB") {
            const newLocation = location.origin + "/#/welcome";
            location.href = newLocation;
          }
          else {
            window.location.href = new URL(location).origin;
          }
        }
        else if (rmId && (rmId != this.rmData["transaction_id"])) {
          sessionStorage.clear();
          localStorage.clear();
          this.startSession(rmId);
        }
        if (JSON.parse(sessionStorage.getItem("sessionExpiryTime")) == true) {
          this.tokenWarning();
        }
      } else if (!this.$store.state.token) {
        if (rmId) {
          this.startSession(rmId)
        }
      }
      if (!this.$store.state.configData && this.$store.state.token) {
        this.getConfigData();
      }
    }

    // Token warning 
    // setInterval( function(){
    //   $("#tokenWarningModal").modal("show");
    // },5000);
  },
  methods: {
    getAllCookies() {
      var cookies = {};
      var cookieArray = document.cookie.split(';');
      for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i].trim();
        var parts = cookie.split('=');
        var name = parts[0];
        var value = parts.slice(1).join('=');
        try {
          value = decodeURIComponent(value);
        } catch (error) {
          // If decoding fails, leave the value as is
        }
        cookies[name] = value;
      }

      return cookies;
    },
    deleteAllCookies() {
      var allCookies = this.getAllCookies();
      for (var cookieName in allCookies) {
        if (allCookies.hasOwnProperty(cookieName)) {
          document.cookie = `${cookieName}=; expires=` + new Date(Date.now() - 10 * 24 * 60 * 60 * 1000).toUTCString() + "; domain=.hdfcsec.com; path=/";
        }
      }
    },
    removeResumeData()
    {
       sessionStorage.removeItem("resumeProfile");
       localStorage.removeItem("resumeProfile");
    },
    disabledLink()
    {
      if(sessionStorage.getItem('vuex')==null)
      return true;
      else
      return false;
    },
    getToken() {
      this.backendApi("getToken")
      .then((response) => {
        //console.log('save quote response: ', response);
          if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data){
          //console.log('response token: ', response.data)

          let token = response.data.Data.Token;
          let USERID = response.data.Data.UserID;
          let key = this.rmData.Userid;

          let spdetail_flag= process.env.VUE_APP_SPDETAIL_ONESB;
          if(spdetail_flag == 0)
          {
            this.getSPDetails(token, USERID, key);
          }
        }

        
      })
      .catch((error) => {
        console.log('error msg block get token', error);
        let tmpData = {
            "advisorCode": "149306",
            "channelType": "BR",
            "cusBankAccNo": "",
            "bankName": "BBBJ",
            "needRiskProfile": "",
            "csrLimCode": "99999 (Dummy code)",
            "cafosCode": "",
            "oppId": "",
            "fscCode": "01325137",
            "spCode": "109337",
            "bankBrnch": "SBIN",
            "source": "0BBJ",
            "lanNo": "",
            "selectedTab": "",
            "subChannel": ""
          };
          
          sessionStorage.setItem("sp_details_data", JSON.stringify(tmpData));
          localStorage.setItem("sp_details_data", JSON.stringify(tmpData));
      });
    },
    getSPDetails(token, USERID, key) {
      let qData = {
        "data":{
          "UserID": USERID, 
          "Token": token,  
          "Key": key 
        }
      }
      this.backendApi("getSPDetails", qData)
      .then((response) => {
          if(response == 'error') {
          self.apiError = true;
        } else if(response && response.data) {
          //console.log('response sp details: ', response.data)
          // sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.Data));
          // localStorage.setItem("sp_details_data", JSON.stringify(response.data.Data));
          let tmpData = {
            "advisorCode": "00050007",
            "channelType": "BR",
            "cusBankAccNo": "",
            "bankName": "BBBJ",
            "needRiskProfile": "",
            "csrLimCode": "99999",
            "cafosCode": "",
            "oppId": "",
            "fscCode": "01370250",
            "spCode": "93432",
            "bankBrnch": "BJ00",
            "source": "0BBJ",
            "lanNo": "",
            "selectedTab": "",
            "subChannel": ""
          };
          
          sessionStorage.setItem("sp_details_data", JSON.stringify(tmpData));
          localStorage.setItem("sp_details_data", JSON.stringify(tmpData));
        }
      })
      .catch((error) => {
        console.log('error msg block sp', error);
      });
    },
    startSession(rmId){
      let data = {
        transactionId: rmId
      }
      this.backendApi("generateToken", data)
      .then((resp) => {
        let token = resp.data.data.token.access_token;
        this.$store.commit('setToken',token);
        
        this.fetchRmDetails(token);
        
      })
      .catch((error) => {
        console.log(error);
        console.log("Failed to start session");
      })
    },
    fetchRmDetails(token){
      this.backendApi("getRmDetails", [], {"Authorization":`Bearer ${token}`}, "get")
        .then((response) => {
          if(response == 'error') {
          } else if(response && response.data) {
            this.rmData = response.data.data;

              if(this.rmData.RoleId == '1') {
                sessionStorage.setItem("channel_type", JSON.stringify("B2C"));
              } else {
                sessionStorage.setItem("channel_type", JSON.stringify("B2B"));
              }
              sessionStorage.setItem("rm_data", JSON.stringify(this.rmData));
              localStorage.setItem("rm_data", JSON.stringify(this.rmData));
              sessionStorage.setItem("sessionExpiryTime",'true');
              if (location.href.includes("dashboard")) {
                const newLocation = location.origin + "/#/dashboard";
                location.href = newLocation;
                window.location.reload();
              } else {
                      if(getConsumer()["consumerId"] == "KVB"){
                      const newLocation = location.origin + "/#/welcome";
                      location.href = newLocation;
                      }
                      else{
                          window.location.href = new URL(location).origin;
                        }
              }
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
        });
    },
    getConfigData() {

      var headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        "Content-Type": "application/json",
      };

      let reqData = [getConsumer()['consumerId']];
        
      this.backendApi("getConfigData", reqData, headers, "get")
        .then((response) => {
          if (response == "error") {
            console.log("get config data error: block");
          } else if (response && response.data) {
            console.log("get config data success: response ", response.data.data.Jsondata[0].jsonDataUI);
            this.$store.commit('setConfigData', response.data.data.Jsondata[0].jsonDataUI)
            console.log("hdjfh",this.$store.state.configData)
          }
        })
        .catch((error) => {
          console.log("error msg block get config data", error);
        });

    },
    logout(){
      const logOutUrl = this.rmData && this.rmData.LogOutUrl;
      sessionStorage.clear();
      localStorage.clear();
      if (sessionStorage.getItem("rm_data")){
        this.rmData = JSON.parse(sessionStorage.getItem("rm_data"))
      }
      else
        this.rmData = "";
      if (logOutUrl && logOutUrl !== null) {
        window.location.href = logOutUrl;
      } else {
        window.location.href = new URL(location).origin;
      }
    },
    tokenWarning() {
      let self = this;
      this.intervalTime = setInterval( function(){
        let decodedToken = self.parseJwt(self.$store.state.token);
        // console.log("Buffor TTTTTTT",((decodedToken['expires'] - Date.now() / 1000) / 60));
        let timer = ((decodedToken['expires'] - Date.now() / 1000) / 60);
        if (timer <= 1 && timer > 0) {
          self.tokenWarningFlag = true;
          if(self.tokenExpireWarningCancel == true){
            sessionStorage.setItem("sessionExpiryTime",'false');
            clearInterval(self.intervalTime);
            self.tokenWarningFlag = false;
          } 

          if(self.tokenExpireWarning == true){
            self.tokenWarningFlag = false;
            let data = {
              currentToken: self.$store.state.token
            }
            self.backendApi("refreshToken", data)
            .then((resp) => {
              if(resp.data.errors.length>0){
                self.$router.push({ path: '/networkalert', query: {msg: resp.data.errors[0]['errorMessage'] }});
              }
              self.tokenExpireWarning = false;
              let token = resp.data.data.newToken.access_token;
              self.$store.commit('setToken',token);
              self.callTokenWarning();
            })
            .catch((error) => {
              console.log("Failed to start session");
            })
          }
        }
        else if(timer <= 0){
          self.tokenWarningFlag = false;
          sessionStorage.setItem("sessionExpiryTime",'false');
          clearInterval(self.intervalTime);
          sessionStorage.removeItem('vuex')
          window.location.href = `${window.location.origin}/#/networkalert?msg=Your session time is expired , kindly login again&backUrl=logout`;
        } else {
          //console.log("else block executed");
        }
      },1000);
    },
    callTokenWarning(){
      clearInterval(this.intervalTime);
      this.tokenWarning();
    },
    parseJwt(token) {
      return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    },
    closeAlertDialog() {
      this.tokenWarningFlag = false;
    }
    
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
