<template>
  <div>
    <Header />
    <login />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import login from "@/components/welcome-login/welcome-login.vue";

export default {
  name: 'loginpage',
  components: {
    Header,
     login
  }
}
</script>

<style scoped lang="scss">
</style>