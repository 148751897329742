<template>
    <div>
        <!-- <innerheader /> -->
        <alertdiypage />
    </div>
</template>

<script>
// @ is an alias to /src
// import innerheader from "@/components/innerheader/innerheader.vue";
import alertdiypage from "@/components/alert/alertdiypage.vue";

export default {
  name: 'alertDiy',
  components: {
    //  innerheader,
     alertdiypage
  }
}
</script>

<!-- <style scoped lang="scss">
@import '~@/assets/scss/components/paymentsuccess.scss';
</style> -->