<template>
    <div class="">
        <loadingPage />
    </div>
</template>

<script>
import loadingPage from "@/components/loading.vue";

export default {
    name: "loading",
    components: {
        loadingPage,
    },
};
</script>
