  <template>
    <div class="disclaimer p-3" v-if="message1 || message2 || message3"><hr/>
        <p>{{ message1 }}</p>
        <p>{{ message2 }}</p>
        <p>{{ message3 }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "disclaimer",
    props: {
      msg: String,
      page: String
    },
    mounted() {
        let configData2 = this.$store.state.configData.DISCLAIMER;
        let itemIndex = configData2 && configData2.findIndex(item => item.Page == this.page);
        if(itemIndex > -1) {
            this.message1 = configData2[itemIndex].Message1;
            this.message2 = configData2[itemIndex].Message2;
            this.message3 = configData2[itemIndex].Message3;
        }
    },
    data() {
        return {
            configData2: "",
            message1: "",
            message2: "",
            message3: ""
        }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <!-- <style scoped lang="scss">

  </style> -->
  