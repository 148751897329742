<template>
  <div class="inner-page-wrapper">
    <div class="welcome-page d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-md-6 left-col"></div>
          <div class="col-md-6 right-col">
            <div class="right-col-wrapper">
              <div class="form-wrapper">
                <form>
                  <div class="form-group">
                    <label class="form-label">Customer Id</label>
                    <div class="input-wrapper">
                      <input type="text" class="form-control" v-model="customerId" placeholder="Enter your Customer Id" required>
                    </div>
                    <p class="error_msg"></p>
                  </div>
                  <div class="form-btn">
                    <button type="button" class="btn btn-primary" @click="gotoDetail()">Fetch Customer Detail</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import $ from "jquery";
import axios from "axios";
export default {
  name: "welcome",
  components: {
  },
  mixins: [],
  mounted() {

  },
  data() {
    return {
      customerId: "",
    }
  },
  methods: {
    gotoDetail() {
      let qData = { "custId": this.customerId };
      this.backendApi("updateInformation", qData)
        .then((response) => {
          if (response == 'error') {
            self.apiError = true;
          } else if (response && response.data) {
            console.log('response updateInformation: ', response.data)
            if (response.data.data.status == "success") {
              this.getCustomerInfo();
            }
            if (response.data.errors.length > 0) {
              this.$router.push({ path: '/networkalert', query: { msg: resp.data.errors[0]['errorDisplayMessage'] } });
            }
          }

        })
        .catch((error) => {
          console.log('error msg block sp', error);
        });
    },
    getCustomerInfo() {
      let qData = [this.customerId];
      var headers = {};
      this.backendApi("customerInfo", qData, headers, "get")
        .then((response) => {
          console.log("resp in response", response);
          if (response.data.data) {
            sessionStorage.setItem("customerInfo", JSON.stringify(response.data.data));
            this.$router.push("/");
          }
          if (response.data.errors.length > 0) {
            this.$router.push({ path: '/networkalert', query: { msg: resp.data.errors[0]['errorDisplayMessage'] } });
          }

        })
        .catch((error) => {
          console.log("error msg block customerInfo", error);
        });

    },
  }

};
</script>

<style scoped lang="scss">
@import "~@/scss/pages/welcome.scss";
</style>