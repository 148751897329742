<template>
  <div class="text-center" v-if="loading" style="padding-top: 50px;">
    <loadingPage />
  </div>
  <div v-else class="thankyou">
    <div v-if="selectedPlan">
      <div class="main_header d-flex justify-content-between align-items-center mx-5 my-3" >
        <div class="d-flex align-items-center">
          <div>
            <img :src="selectedPlan?.supportingData?.insCompanyLogo" alt="image" />
          </div>
          <div class="ms-4"> 
            <h4>{{ this.productName }}</h4>
          </div>
        </div>
        <div class="propno">
          
          <!-- <span v-if="this.applicationNo">Insurer Application Number: {{this.applicationNo}}</span><br> -->
          <span v-if="this.referenceNumber">CRN: {{this.referenceNumber}}</span>
      
        </div>
      </div>
      <div class="container">
          <div class="success-img-holder text-center mb-3">
            <figure v-if="uwResponse?.data?.policy?.policyStatus == 'RJ' || uwResponse?.data?.policy?.policyStatus == 'Rejected'">
              <!-- <img src="~@/assets/images/payment_scuccessful.png" alt="" />
              <h1 class="text-success">Payment successfull !</h1> -->
            </figure>
            <figure v-else>
              <img src="~@/assets/images/payment_scuccessful.png" alt="" />
              <h1 class="text-success" v-if="this.isArka && this.arkaJurneyStatus === 'Payment Initiated'">{{"Proposal Submitted."}}</h1>
              <h1 class="text-success" v-else-if="this.isArka && this.arkaJurneyStatus === 'Payment Success'">{{"Payment Successful !"}}</h1>
              <h1 class="text-success" v-else>{{"Payment Successful !"}}</h1>
            </figure>
            
            <figcaption>
              
              <template v-if="uwResponse?.data?.policy?.policyStatus == 'RJ' || uwResponse?.data?.policy?.policyStatus == 'Rejected'">
                <h5 class="text-danger">We regret to state that this proposal has been rejected by the insurance company.</h5>
                <span>This may be due to product features or insured’s health conditions. We request you to get in touch with this insurance company or try another product.</span>
              </template>
              <template v-else>
                <h5>Thank you and Congratulations on your new health Insurance policy.</h5>
                <span>Your proposal is submitted successfully to insurance company and the details are shown below</span>
              </template>
              <div class="table-responsive payment-table">
                <table>
                  <tr>
                    <th>Product Name</th>
                    <td class="thankutext">{{ this.productName }}</td>
                  </tr>
                  <tr>
                    <th>Plan Name</th>
                    <td class="thankutext">{{ this.planName }}</td>
                  </tr>
                  <template v-if="uwResponse?.data?.policy?.policyStatus != 'RJ' && uwResponse?.data?.policy?.policyStatus != 'Rejected'">
                    <tr v-if="this.policyNo && this.policyNo != 'None'">
                      <th>Policy Number</th>
                      <td class="thankutext">{{ this.policyNo }}</td>
                    </tr>
                    <tr v-if="!this.policyNo || this.policyNo == 'None'">
                      <th>Proposal Number</th>
                      <td class="thankutext">{{ this.applicationNo }}</td>
                    </tr>
                    <tr>
                      <th>Premium Amount</th>
                      <td class="thankutext">
                        <em class="rupee">&#x20B9;</em>
                        {{
                          currency(selectedPlan.totalPremiumDetails[selectedPolicyIndex]?.totalPremiumWithTax)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>Payment Status</th>
                      <td class="thankutext" v-if="this.isArka && this.arkaJurneyStatus === 'Payment Initiated'">Initiated</td>
                      <td class="thankutext" v-else-if="this.isArka && this.arkaJurneyStatus === 'Payment Success'">Success</td>
                      <td class="thankutext" v-else>Success</td>
                    </tr>
                  </template>
                  <!-- <tr v-else>
                    <th>Payment Status</th>
                    <td class="thankutext">Failure</td>
                  </tr> -->
                </table>
              </div>
            </figcaption>
            <template v-if="uwResponse?.data?.policy?.policyStatus != 'RJ' && uwResponse?.data?.policy?.policyStatus != 'Rejected'">
              <div v-if="!this.policyNo || this.policyNo == 'None'">
                NOTE: Insurer will get in touch with you for next set of processes to issue your policy.
                You can also reach them referring the above Proposal number.
              </div>
            </template>
            <div v-if="(uwResponse?.data?.policy?.policyStatus != 'RJ' || uwResponse?.data?.policy?.policyStatus != 'Rejected') && !this.policyPdf.pdfBytes && !this.isArka" class="before-download">
              <div v-if="loading2" class="loader-2 mt-2">
                Checking Policy Status...<br/>
                <img  class="loader2" src="~@/assets/images/loading_gif.svg" alt="" />
              </div>
              
              <button v-else @click="downloadPolicy()">
                Get Policy Document
              </button>
            </div>
            <div class="before-download" v-if="policyPdf && loading2 == false">
              <button @click="downloadPdf" :download="policyNo">
                Download Policy Copy &nbsp;<em class="download-new"></em>
              </button>
            </div>
            <div v-if="errMsg.length > 0">
              <div class="text-danger" role="">
                <div v-for="(value, ind) in errMsg" :key="ind">
                  {{ value }}
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div v-else-if="urlMsg">
      <div class="container">
        <div class="success-img-holder text-center mb-3">
          <span>{{ this.urlMsg }}</span>
        </div>
      </div>
    </div>
    <!-- Disclaimer Section -->
    <Disclaimer :page="'ThankYou'"/>
    <!--.\ Disclaimer Section -->
  </div>
</template>


<script>
import loadingPage from "@/components/loading5.vue";
import Disclaimer from "@/components/disclaimer.vue";
export default {
  name: "paymnt",
  props: {  },
  components: {
    loadingPage,
    Disclaimer
  },
  data() {
    return {
      userData:{},
      email:"",
      mobile:"",
      maskid:"",
      hiddennum:"",
      referenceNumber:"",
      applicationNo:"",
      insuranceCompanyCode:"",
      selectedPolicyIndex: 0,
      productName:"",
      planName: "",
      selectedPlan: "",
      loading: false,
      loading2: false,
      requestJson: "",
      policyNo: "",
      policyPdf: "",
      configData: "",
      channel_type: "",
      uwResponse: "",
      customerId:"",
      urlMsg: "",
      payResponse: "",
      proposalUiId: "",
      errMsg: "",
      isArka: false,
      arkaJurneyStatus:"",
      policy_status: "",
      isPolicyPdfAvailable: false,
      policyStatusSetting: "",
      callPolicyStsCounter: 0,
    };
  },
  mounted() {
    
    this.configData = this.$store.state.configData;
    /* if(sessionStorage.getItem("uiQuoteId"))
    this.referenceNumber=sessionStorage.getItem("uiQuoteId") */

    this.policyStatusSetting = this.configData["POLICY_STATUS"];

    this.urlMsg = this.$route.query.msg;
    console.log('urlMsg: ', this.urlMsg);
 
    this.selectedPlan = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    if(sessionStorage.getItem("isArka")){
      this.isArka = JSON.parse(sessionStorage.getItem("isArka"));
      this.arkaJurneyStatus = sessionStorage.getItem('arkaJourneyStatus');
    }
    if(sessionStorage.getItem("pay_response")) {
      this.payResponse = JSON.parse(sessionStorage.getItem("pay_response"));
    }

    if(sessionStorage.getItem("proposal_ui_id")) {
      this.proposalUiId = JSON.parse(sessionStorage.getItem("proposal_ui_id"));
    }

    if(sessionStorage.getItem("policy_status") && sessionStorage.getItem("policy_status") !== "undefined") {
      this.policy_status = JSON.parse(sessionStorage.getItem("policy_status"));
    }

    if(this.selectedPlan) {
      this.requestJson = sessionStorage.getItem("quote_request") ? JSON.parse(sessionStorage.getItem("quote_request")) : sessionStorage.getItem("request_json") ? JSON.parse(sessionStorage.getItem("request_json")) : "";
      this.insuranceCompanyCode=this.selectedPlan["insuranceAndProducts"]["insuranceCompanyCode"];
      this.productName=this.selectedPlan["insuranceAndProducts"]["productName"];
      this.planName=this.selectedPlan["productDetails"]["planOption"]["planName"];
      this.selectedPolicyIndex = this.getSelectedPolicyIndex(this.requestJson?.product?.policyTerm);

      this.userData = JSON.parse(sessionStorage.getItem("filledData"));
      this.email = this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["email"]["input"];
      this.mobile = this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["mobileNumber"]["input"];
      console.log("emailmob",this.email,this.mobile)

      this.channel_type = JSON.parse(sessionStorage.getItem("channel_type"));

      if(sessionStorage.getItem("policyNo") && sessionStorage.getItem("policyNo") != '') {
        this.policyNo = sessionStorage.getItem("policyNo");
      } else if(this.payResponse && this.payResponse['varFields']['policyNumber']) {
        this.policyNo = this.payResponse['varFields']['policyNumber'];
      }
      if(sessionStorage.getItem("applicationNumber")) {
        this.applicationNo = sessionStorage.getItem("applicationNumber");
      }
      if(sessionStorage.getItem("customerId")) {
        this.customerId = sessionStorage.getItem("customerId");
      }
      
      this.uwResponse = JSON.parse(sessionStorage.getItem("uw_response"));

      console.log('this.UEW: ', this.uwResponse?.data?.policy?.policyStatus);
    }
    

    console.log("data",this.userData)
    this.maskid = this.email.replace(/^(.)(.*)(.@.*)$/,
     (_, a, b, c) => a + b.replace(/./g, '*') + c);
    this.hiddennum= this.mobile.slice(0, 2) + this.mobile.slice(2).replace(/.(?=...)/g, '*');

    var self = this;
    if(!this.isArka){
      self.downloadPolicy();
    }
    if(this.policyStatusSetting){
      let policy_status_mode = this.policyStatusSetting[this.selectedPlan['insuranceAndProducts']['productCode']];
      if(!policy_status_mode) {
        policy_status_mode = this.policyStatusSetting[this.selectedPlan['insuranceAndProducts']['insuranceCompanyCode']];
      }
      if(policy_status_mode == 'yes') {
        this.getPolicyStatus();
      } else {
        if(this.policy_status != 'RJ' && this.policy_status != 'Rejected'){
          self.downloadPolicy();
        }
      } 
    }

    

  },
  methods: {
    downloadPolicy() {
      var self = this;
      this.errMsg = "";
      console.log('downloadPolicy called: ');
      this.loading2 = true;
      let headers = {};
      let reqData = {
          "distributor": {
              "distributorID": this.configData["CONSUMER"]["consumerId"],
              "agentID": "",
              "salesChannel": "",
              "channelType":this.channel_type,
              "varFields": [{
                  "fieldName":"correlationId",
                  "fieldValue":this.selectedPlan.trackInfo.manufacturerQuoteID
              },
              {
                  "fieldName":"customerId",
                  "fieldValue":this.customerId
              }
              ]
          },
          "UITrackingRefNo": this.proposalUiId,
          "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,
          "applicationNo": this.applicationNo.toString(),
          "productCode": this.selectedPlan.insuranceAndProducts.productCode,
          "policyNo": (this.policyNo)?this.policyNo.toString():this.applicationNo.toString(),
          "quoteId": this.selectedPlan.trackInfo.manufacturerQuoteID,
          "individualDetails": {
              "firstName": this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["firstName"]["input"],
              "lastName": this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["lastName"]["input"],
              "dob": this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["dateOfBirth"]["input"],
              "mobileNumber": parseInt(this.mobile)
          },
          "fileDetails": {
              "SBDocCategory": "policyDownload",
              "SBDocId": "policyDownloadDoc",
              "varFields": [
                  {
                      "fieldName": "",
                      "fieldValue": ""
                  }
              ]
          } 
      };

      this.backendApi("downloadPolicy", reqData, headers)
        .then((response) => {
          this.loading2 = false;
         
          if (response.status == "error") {

            let msg = [response.data.detail];
            //this.$router.push({ path: '/networkalert', query: { backUrl: 'thankyou-page', msg: msg } });
            this.errMsg = msg;
            
          } if(response?.data?.errors?.length>0) {
            let msg = [];
            /*for(let i=0; i<response?.data?.errors?.length; i++)
            {
              msg.push(response.data.errors[i]["errorDisplayMessage"]);
            } 
            this.errMsg = msg; */
            if(this.policyNo && !this.policyPdf.pdfBytes) {
              msg['0'] = 'Policy Copy is still not available for Download. Please try after some time.';
            } else if(this.applicationNo && !this.policyNo){
              msg['0']  = 'Policy is still with Underwriting.Please try after some time.';
            }
            this.errMsg = msg;
            
          } else  {
            //console.log('response: ', response.data);
            
            this.policyPdf = response.data.data;
            if(this.policyPdf.manufacturerTracking.policyNumber) {
              this.policyNo = this.policyPdf.manufacturerTracking.policyNumber;
              sessionStorage.setItem("policyNo",this.policyPdf.manufacturerTracking.policyNumber);
              let qData = {
                "proposal_ui_id": this.proposalUiId,
                "policy_number": this.policyPdf.manufacturerTracking.policyNumber
              };
              this.saveApplicationNumber(qData);
              this.updateProposalStatus(9);
            }
          }

        })
        .catch((error) => {
          console.log('error msg block : get policy', error);
          self.loading = false;
          let msg=['We are facing some issue while downloading policy'];
          this.errMsg = msg;
        });
    },
    getPolicyStatus()
    {
      let self = this;
      console.log('Policy status called: ');
        let qData =  {
          "distributor": {
            "distributorID": this.configData["CONSUMER"]["consumerId"],
            "agentID": "",
            "agentType": "",
            "salesChannel": "",
            "channelType": this.channel_type,
            "varFields": []
          },
          "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,
          "applicationNo": this.applicationNo.toString(),
          "policyNo": "",
          "quoteId": this.selectedPlan.trackInfo.manufacturerQuoteID,
          "memberDetails": {
            "dateofBirth": this.userData["fieldGroups"]["proposerDetails"]["fieldGroups"]["personalDetails"]["fields"]["dateOfBirth"]["input"],
          },
          "varFields": []
        };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        
        console.log('qData: ', qData)

        this.backendApi("getPolicyStatus", qData, headers)
          .then((response) => {
            if(response == 'error') {
              self.apiError = true;
              // let msg=['We are facing some issue while saving proposal status']
              // this.$router.push({ path: '/alert-diy', query: {msg: msg }});
            } if(response?.data?.errors?.length>0) {
              if(response?.data?.errors[0]?.errorCode == 'REQUEST_TIMEOUT_ERROR') {
                this.callPolicyStsCounter += 1;
                if(this.callPolicyStsCounter <= 3) {
                  self.getPolicyStatus();
                }
              }
            } else if(response && response.data.data) {
              if(response?.data?.data?.manufacturer[0]?.product[0]?.policyDetails?.policyNumber) {
                this.policyNo = response?.data?.data?.manufacturer[0]?.product[0]?.policyDetails?.policyNumber;
                console.log('got policy number in get policy status: ', response?.data?.data?.manufacturer[0]?.product[0]?.policyDetails?.policyNumber);
                if(this.policyNo && response?.data?.data?.manufacturer[0]?.product[0]?.policyDetails?.policyStatus == 'Policy Issued') {
                  this.updateProposalStatus(8);
                } else {
                  this.updateProposalStatus(4);
                }
              } 
              // Calling download policy after calling get policy status
              if(this.policy_status != 'RJ' && this.policy_status != 'Rejected'){
                self.downloadPolicy();
              }
              
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
            // let msg=['We are facing some issue from backend while saving proposal status']
            //   this.$router.push({ path: '/alert-diy', query: {msg: msg }});
          });
    },
    updateProposalStatus(statusid)
    {
        let qData = {
          "proposal_ui_id":this.proposalUiId,
          "status_id": statusid
        };
        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        
        this.backendApi("ProposalStatus", qData, headers)
          .then((response) => {
            if(response == 'error') {
              self.apiError = true;
              // let msg=['We are facing some issue while saving proposal status']
              // this.$router.push({ path: '/alert-diy', query: {msg: msg }});
            }
            
             else if(response && response.data) {
              console.log('proposal status updated: ', response.data)
            }
          })
          .catch((error) => {
            console.log('error msg block email', error);
            // let msg=['We are facing some issue from backend while saving proposal status']
            //   this.$router.push({ path: '/alert-diy', query: {msg: msg }});
          });
    },
    saveApplicationNumber(qData){
        console.log('saveApplicationNumber called');
        var headers = {
          "quoteId":this.uiQuoteId,
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
          };
        
        this.backendApi("saveProposal", qData, headers)
          .then((response) => {
            if(response == 'error') {
              //self.apiError = true;
              console.log('error response');
            } else if(response) {
              console.log('Application Number Updated Successfully: ', response.data);
            }
          })
          .catch((error) => {
            console.log('error msg block Save Application Number', error);
          });
    },
    getSelectedPolicyIndex(pTerm) {
      let itemIndex = this.selectedPlan.productDetails.premiumForEachPolicyTerm.findIndex(item => item.policyTerm == pTerm);
      if(itemIndex > -1) {
        return itemIndex;
      }
    },
    currency(amt) {
      const tmpAmt = parseFloat(amt).toFixed(2);
      return new Intl.NumberFormat().format(tmpAmt);
    },
    downloadPdf() {
      const byteCharacters = window.atob(this.policyPdf.pdfBytes);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.policyNo;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped lang="scss">
//@import "~@/assets/scss/components/paymentsuccess.scss";
// @import "~@/assets/scss/components/paymentfailed.scss";
.thankutext {
  color: #0a3f88;
}
table {
  text-align: left;
  width: 40%;
  margin: 0px auto;
}
</style>