export const getRequestData = (api, data) => {
  
  console.log('getRequestData', api, 'URL: ', process.env.VUE_APP_URL)
  return {
    body: data,
    url: apis[api]["url"],
    baseURL:apis[api]["baseURL"] == undefined ? process.env.VUE_APP_URL : process.env["VUE_APP_URL_" + apis[api]["baseURL"]],
    source:apis[api]["baseURL"] == undefined ? "DEFAULT": apis[api]["baseURL"],
  };
};

let isArka = false;
if (JSON.parse(sessionStorage.getItem('isArka'))) {
  isArka = JSON.parse(sessionStorage.getItem('isArka'));
}
const category = isArka ? 'grphlth' : 'health';

const apis = {
  /*paymentIntimation:{
    url:"v2/health/payment/intimation",
    baseURL: "RMD"
  },*/
  getPolicyStatus:{
    url:"/v2/health/proposal/status",
    baseURL: "RMD"
  },
  downloadPolicy:{
    url: "/v2/health/receipt/download",
    baseURL: "RMD"
  },
  getComparePlan: {
    url: "/v2/health/compare",
    baseURL: "RMD"
  },
  saveProposalDetailsData: {
    url: `/v2/${category}/proposal/details`,
    baseURL: "RMD"
  },
  getProposalDetailsData: {         
    url: "/v2/health/proposalDetails?proposal_ui_id=param1",
    baseURL: "RMD"
  },
  proposalGenericApiUw: {         
    url: "/v2/health/proposal/generic/uw",
    baseURL: "RMD"
  },
  getCkyc: {
    url: "/v2/health/ckyc",
    baseURL: "RMD"
  },
  paymentApi: {
    url: "/v2/health/payment",
    baseURL: "RMD"
  },
  getPincodeInfo: {
    url: "/v2/health/zipcode?zipcode=param1",
    baseURL: "RMD"
  },
  getConfigData: {
    url: "/v2/health/configuration?distributor_id=param1",
    baseURL: "RMD"
  },
  getQuotation: {
    url: `/v2/${category}/quote`,
    baseURL: "RMD"
  },
  // getPolicies: {
  //   url: "/v2/health/getLifeTermApi?requestId=param1",
  //   baseURL: "RMD"
  // },
  getProductUi: {
    url: "/v2/health/product/information?manufacturer_id=param1&product_id=param2&plan_id=param3",
    baseURL: "RMD"
  },
  saveQuote: {
    url: "/v2/quote",
    baseURL: "RMD"
  },
  productSelection: {
    url: "/v2/health/product/param1?multi_quote_response_id=param2",
    baseURL: "RMD"
  },
  saveQuoteResponse: {
    url: "/v2/quoteResponse",
    baseURL: "RMD"
  },
  generateToken:{
    url: "/v2/token?tokenType=SESSION",
    baseURL: "RMD"
  },  
  getRmDetails: {
    url: "/v2/saving/user",
    baseURL: "RMD"
  },
  // Dashboard Related
  getDashboardData:{
    url: "/v2/health/dashboard?pageSize=param1&pageNumber=param2&columnName=param3&filterText=param4&startDate=param5&endDate=param6",
    baseURL: "RMD"
  },
  getProposalDetails: {         
    url: "/v2/health/proposal/draft?journey_id=param1",
    baseURL: "RMD"
  },
  getSPDetailsOneSB: {
    //url: "/v2/spdetails?manufacturerId=param1",
    url: "/v2/health/spDetails?manufacturerId=param1",
    baseURL: "RMD"
  },
  getQuoteDetails: {         
    url: "/v2/health/quoteDetails?UiQuoteId=param1",
    baseURL: "RMD"
  },

  saveLogData: {
    url: "/v2/logData",
    baseURL: "RMD"
  },
  getLogData: {
    url: "/v2/logdata?mobileNumber=param1&DOB=param2",
    baseURL: "RMD"
  },
 
  triggerPayment: {
    url: "/v2/health/hdfcPayment/param1",
    baseURL: "RMD"
  },
  sendMail: {
    url: "/v2/mail/send",
    baseURL: "RMD"
  },

  getQuoteResponseDetails: {         
    url: "/v2/health/quoteResponse?UiQuoteId=param1",
    baseURL: "RMD"
  },
  ProposalStatus: {
    url: "/v2/health/proposalStatus",
    baseURL: "RMD"
  },
  savePaymentData:{
    url:  "/v2/health/paymentDetails",
    baseURL: "RMD"
  },
  saveProposal: {
    url: `/v2/${category}/proposal/draft`,
    baseURL: "RMD"
  },
  saveResumeURL:{
    url: "/v2/manufacturerResumeURL",
    baseURL: "RMD"
  },
  getResumeURL: {
    url: "/v2/manufacturerResumeURL?proposal_ui_id=param1",
    baseURL: "RMD"
  },
  getPendingUploadDocRequiremnts:{
    url: "/v2/pendingDocumentUploadList?id=param1",
    baseURL: "RMD"
  },

  quotationApiGet: {
    url: "/v2/health/quotationApi",
    baseURL: "RMD"
  },
  saveApplicationNumber: {
    url: "/v2/saveProposalManufacturerId",
    baseURL: "RMD"
  },
  // getQuotation: {
  //   url: "/v2/health/quotationApi",
  //   baseURL: "RMD"
  // },
  getPolicies: {
    url: `/v2/${category}/quote/poll?request_id=param1`,
    baseURL: "RMD"
  },
  // saveQuote: {
  //   url: "/v2/quote",
  //   baseURL: "RMD"
  // },
  saveQuoteResponse: {
    url: "/v2/quoteResponse",
    baseURL: "RMD"
  },
  getToken: {
    url: "/v2/getTokenProxy",
    baseURL: "RMD"
  },
  getSPDetails: {
    url: "/v2/SPDetailsProxy",
    baseURL: "RMD"
  },
  // getSPDetailsOneSB: {
  //   url: "/v2/spdetails?rmCode=param1&manufacturerId=param2",
  //   baseURL: "RMD"
  // },
  
  // getProductInfo: {
  //   url: "/quote/getProductUIData?manufacturerId=param1&productId=param2",
  //   baseURL: "TEST"
  // },
  getPincodeInfo: {
    url: "/v2/health/zipcode?zipcode=param1",
    baseURL: "RMD"
  },
  getIFSCInfo: {
    url: "/v2/health/ifsc?ifscCode=param1",
    baseURL: "RMD"
  },
  triggerPayment: {
    url: "/v2/health/hdfcPayment/param1",
    baseURL: "RMD"
  },
  getProposal: {
    url: `/v2/${category}/proposal?product_id=param1&manufacturer_id=param2&version=param3`,
    baseURL: "RMD"
  },
  validateProposal:{
    url: "/v2/health/proposalValidationProxy",
    baseURL: "RMD"
  },
  // submitProposal: {
  //   url: "proposal?version=1",
  //   baseURL: "PROPOSAL"
  // },
  // getProposalPoll: {
  //   url: "proposal/poll?productId=param1&manufacturerId=param2&requestId=param3",
  //   baseURL: "PROPOSAL"
  // },
  // getReqId: {
  //   url: "/quote"
  // },
  // getLifeTerm: {
  //   url: "/quote/param1"
  // },
  gateCriteria: {
    url: "/v2/health/gateCriteria?productGroupId=health&productId=param1&manufacturerId=param2&version=param3",
    baseURL:"RMD"
  },
  getDocRequirements: {
    url: "/v2/health/gateDocRequirements",
    baseURL: "RMD"
  },
  // ProposalStatus: {
  //   url: "/v2/ProposalStatus?proposal_ui_id=param1&status_id=param2",
  //   baseURL: "RMD"
  // },
  getProposalStatus: {
    url: "/v2/health/journey?journey_id=param1",
    baseURL: "RMD"
  },
  // getRmDetails: {
  //   url: "/v2/getRM_Details",
  //   baseURL: "RMD"
  // },
  getRmDetailsDIY: {
    url: "/v2/health/user_diy",
    baseURL: "RMD"
  },
  // getDashboardData:{
  //   url: "/v2/getProposalByRm?rmCode=param1&pageSize=param2&pageNumber=param3",
  //   baseURL: "RMD"
  // },
  /*getDashboardDataFilter:{
    url: "/v2/getProposalByRmFilter?rmCode=param1&pageSize=param2&pageNumber=param3&columnName=param4&filterText=param5&startDate=param6&endDate=param7",
    baseURL: "RMD"
  },*/
  sendEmailandSMS: {
    url: "/v2/health/communication",
    baseURL: "RMD"
  },
  getRequirements:{
    url: "/v2/getRequirementsByProposal?proposalId=param1",
    baseURL: "RMD"
  },
  getPaymentDetails:{
    url: "/v2/getPaymentDetail?transactionId=param1",
    baseURL: "RMD"
  },
  getPaymentDetailsByProposalUiId:{
    url: "/v2/health/paymentDetails?proposal_ui_id=param1",
    baseURL: "RMD"
  },
  // sendSMS: {
  //   url: "/sendsms?username=param1&password=param2&to=param3&from=param4&udh=param5&text=param6",
  //   baseURL: "SMS"
  // },
  // getOTP: {
  //   url: "/sendotp",
  //   baseURL: "PROPOSAL"
  // },
  // submitOTP: {
  //   url: "/valotp",
  //   baseURL: "PROPOSAL"
  // },
  uploadDoc: {
    url: "/v2/health/uploadDoc",
    baseURL: "RMD"
  },
  getWhatsappDetails: {
    url: "/v2/consentMaster",
    baseURL: "RMD"
  },
  saveProposalResponse: {
    url: "/v2/proposalResponse",
    baseURL: "RMD"
  },
  // getPaymentURL: {
  //   url: "/v2/payment/url",
  //   baseURL: "PAYMENT"
  // },
  quotationApiGet: {
    url: "/v2/health/quotationApi",
    baseURL: "RMD"
  },
  
  proposalApiGet: {
    url: "/v2/proposalApi",
    baseURL: "RMD"
  },
  sendOtpApi: {
    url: "/v2/health/otp/send",
    baseURL: "RMD"
  },
  valOtpApi: {
    url: "/v2/health/otp/validate",
    baseURL: "RMD"
  },
  proposalVersionApi: {
    url: "/v2/health/proposal/submit",
    baseURL: "RMD"
  },
  proposalPollApi: {
    url: "/v2/health/proposal/poll?product_id=param1&manufacturer_id=param2&request_id=param3",
    baseURL: "RMD"
  },
  getProductUiDataApi: {
    url: "/v2/health/getProductUiDataApi?manufacturerId=param1&productId=param2",
    baseURL: "RMD"
  },
  paymentUrlApi: {
    url: "/v2/health/payment",
    baseURL: "RMD"
  },
  getLifeTermApi: {
    url: "/v2/health/getLifeTermApi?requestId=param1",
    baseURL: "RMD"
  },
  downloadReceipt:{
    url: "/v2/health/receipt/download",
    baseURL: "RMD"
  },
  uploadDocRequiremnts:{
    url: "/v2/documentUpload",
    baseURL: "RMD"
  },
  getUploadDocRequiremnts:{
    url: "/v2/documentUpload?id=param1",
    baseURL: "RMD"
  },
  getPendingUploadDocRequiremnts:{
    url: "/v2/pendingDocumentUploadList?id=param1",
    baseURL: "RMD"
  },
  uploadDocStatus:{
    url: "/v2/documentUploadStatus?applicationNumber=param1&category=param2&status=param3",
    baseURL: "RMD"
  // },
  // redirectpage:{
  //   url: "/v2/1sb/redirect",
  //   baseURL: "RMD"
  },
  // generateToken:{
  //   url: "/v2/token",
  //   baseURL: "RMD"
  // },  
  refreshToken:{
    url: "/v2/token/refresh?tokenType=SESSION",
    baseURL: "RMD"
  },
  getProposalWithStage: {
    url: `/v2/${category}/proposal/draft?journey_id=param1&stage=param2`,
    baseURL: "RMD"
  },
  getSessionData:{
    url: "/v2/journey/session?journeyId=param1",
    baseURL: "RMD"
  },
  validationForm: {
    url: `/v2/${category}/proposal/validate`,
    baseURL: "RMD"
  },
  getJourneyStatus: {
    url: `/v2/journey?journeyId=param1`,
    baseURL: "RMD"
  },
  getProposalPoll: {
    url: `/v2/${category}/proposal/draft?journey_id=param1`,
    baseURL: "RMD"
  },
  updateInformation:{
    url:"/v2/health/updateInformation",
    baseURL: "RMD"
  },
  customerInfo:{
    url:"/v2/health/customerInfo/param1",
    baseURL: "RMD"
  },
  getSPDetailsOneSBWithRm: {
    url: "/v2/grphlth/spDetails?manufacturerId=param1&rmCode=param2",
    baseURL: "RMD",
  },
  
};