<template>
    <div class="Propose-details">
      <ProposerDetailsCheckDiyPage />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import ProposerDetailsCheckDiyPage from "@/components/ProposerDetailsCheckDiyPage.vue";
  
  export default {
    name: "ProposerDetailsCheckDiyView",
    components: {
      ProposerDetailsCheckDiyPage,
    },
  };
  </script>
  