<template>
  <div class="text-center" v-if="loading" style="padding-top: 50px">
    <loadingPage />
  </div>
  <div v-else class="">
    <div class="d-none d-sm-none d-md-block d-lg-block">	
    <Header />
    </div>

    <div class="wrapper ckyc_section">
      <div class="inner-wrapper">
        <router-link to="/addon-rider">
          <div class="d-flex align-items-center py-4 back_forward">	
            <em class="icon-arrow-left me-3"></em>
            <h3 class="mb-0">Back</h3>
          </div>
        </router-link>
       
        <div class="row">
          <div class="col-12 col-sm-12 col-md-8 col-lg-8">
            <div class="tabs_ckyc">
              <ul class="nav nav-tabs wizard-step" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <div class="cir">
                      <i class="bi bi-person"></i>
                      <span class="bi bi-check"></span>
                    </div>
                    <span class="txt">cKYC</span>
                    <div class="line"></div>
                  </button>
                </li>
                <template v-for="(data, ind) in proposalJson.fieldGroups" :key = "ind">
                  <li class="nav-item" role="presentation" v-if="(ind !='traceInfo' && ind != 'distributor') && data.visibility != 'false'">
                    <button
                      class="nav-link disabled"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      <div class="cir">
                        <i class="bi bi-person"></i>
                        <span class="bi bi-check"></span>
                      </div>
                      <span class="txt">
                        {{ checkArray(data) ? data[0].label : data.label }}</span>
                      <div class="line"></div>
                    </button>
                  </li>
                </template>
              </ul>
            </div>
            <div class="ckyc_content">
              <div v-if="msg.length > 0">
                <div class="alert alert-danger" role="alert">
                  <div v-for="(value, ind) in msg" :key="ind">
                    {{ value }}
                  </div>
                </div>
              </div>

              <h4>Lets Verify Proposer KYC</h4>
              <!-- <span class="base-reg">Section to capture the basic cKYC details</span> -->
              <div class="ckyc_details pt-3 mb-3">
                <div class="row mb-3">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="floating-label">
                      <input
                        class="floating-input"
                        type="text"
                        v-model="panNumber"
                        placeholder=""
                        maxlength="10"
                        autofocus
                        id="panNumber"
                        autocomplete="one-time-code"
                      />
                      <label>PAN Number</label>
                      <span class="text-danger">{{
                        errorMsg["pan_number"]
                      }}</span>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="floating-label">
                      <input
                        class="floating-input floating-input-date"
                        type="date"
                        v-model="dateOfBirth"
                        placeholder="Date of Birth"
                      />
                      <label>Date of Birth</label>
                    </div>
                    <span class="text-danger">{{ errorMsg["dob"] }}</span>
                  </div>
                </div>
                <div class="row mb-3 text-center">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <button
                      type="button"
                      class="btn btn-warning rounded-pill text-white mx-2 fetchKycBtn"
                      v-on:click="fetchKyc()"
                      
                    >
                      <span>Fetch KYC</span>
                    </button>
                    <!-- <button
                      type="button"
                      class="btn btn-warning rounded-pill text-white mx-2 fetchKycBtn"
                      v-on:click="uploadDocuments()"
                      
                    >
                      <span>Upload Documents</span>
                    </button> -->
                  </div>
                </div>
              </div>

              <!-- when CKYC details is Successful -->
              <div class="ckyc_success mb-3" v-if="kycStatus == 'approved'">
                <p class="success_msg">
                  Your CKYC is Successfully received and updated in the Proposer
                  form.
                </p>
                <p>
                  You will now be redirected to Proposal page, to fill the
                  missing details.
                </p>
                <br />
                <router-link to="/proposer-details">
                  <button
                    type="button"
                    class="btn btn-warning rounded-pill text-white mx-2"
                  >
                    <span>Continue</span>
                    <em class="bi bi-chevron-right ms-2"></em>
                  </button>
                </router-link>
              </div>
              <!-- when CKYC details fails to fetch -->
              <div class="ckyc_fails mb-3" v-if="kycRedirectUrl">
                <p class="fail_msg mb-3">
                  <!-- <span class="c1">Sorry could not Fetch details from CKYC.</span> -->
                  You will be redirected to insurance company's website in 5
                  seconds. Kindly close the window once you have completed the
                  KYC process. 
                  <br/>Re-click on <a v-on:click="fetchKyc()"><strong>Fetch KYC</strong></a> button.
                </p>
                <p>
                  If you are not redirected to the website, please click here
                  <a target="_blank" :href="kycRedirectUrl"
                    ><u>Verify KYC</u></a
                  >
                </p>
              </div>

              <div class="ckyc_fails mb-3" v-if="kycStatus == 'Incomplete' || kycStatus == 'POAIncomplete'">
                <p class="fail_msg" style="color:red">
                  {{ getStatusWiseMsg(kycStatus) }}
                </p>
                <br />
                <!-- <router-link to="/proposer-details">
                  <button
                    type="button"
                    class="btn btn-warning rounded-pill text-white mx-2"
                  >
                    <span>Continue</span>
                    <em class="bi bi-chevron-right ms-2"></em>
                  </button>
                </router-link> -->
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4">
              
            <div class="content sticky-top">
                <div class="pd-right" v-if="selectedPlan">
                  <div class="pd-right-section1 shadow">
                    <div class="d-flex justify-content-between align-items-center poposer-header">
                      <h3>Policy Details</h3>
                      <div class="ref-num">
                        <h6 v-if="this.applicationNo != '' && this.applicationNo != null " class="appno">Application Number : {{this.applicationNo}}</h6>
                        <!-- <h6 v-if="this.uiQuoteId != '' && this.uiQuoteId != null " class="propno">CRN : {{this.uiQuoteId}}</h6> -->
                        <div class="d-flex justify-content-end" v-if="selectedPlan.other.biUrl">
                          <a :href="selectedPlan.other.biUrl" target="_blank">
                            <button class="btn btn-warning rounded-pill text-white p-1 px-3"> Benefit Illustration </button>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="policy-dts-wrap">
                      <div class="policy-name">
                        <figure>
                          <img :src="selectedPlan?.supportingData?.insCompanyLogo" alt="image" />
                        </figure>
                        <h6 class="mb-0 me-auto">
                          {{ selectedPlan?.insuranceAndProducts?.productName }}
                          <br> {{ selectedPlan.productDetails.planOption.planName }}
                        </h6>
                        <a :href="selectedPlan?.supportingData?.Messages?.brochureLink" target="_blank" class="custom-tooltip">
                          <em class="download-new"></em>
                          <span class="tooltip-content">Download Brochure</span>
                        </a>
                      </div>
                      <div class="p-details">
                        <ul class="policy-item-detail1 mt-4">
                          <!-- <li>
                            <h6 class="mb-0">Base Premium - 
                              {{
                                currency(selectedPlan.productDetails.premiumForEachPolicyTerm[selectedPolicyIndex].policyTerm)
                              }} 
                              Year
                            </h6>
                            <span class="fs-16 base-med"><em class="rupee">&#x20B9;</em>
                            {{
                              currency(selectedPlan.productDetails.premiumForEachPolicyTerm[selectedPolicyIndex].premiumForEachPPO[0].premiumForEachPPF[0].premiumValue)
                            }}
                            </span>
                          </li> -->
                          <li>
                            <h6>Sum Insured</h6>
                            <span> ₹ {{
                              convertInLakh(
                                selectedPlan?.individualDetails[0]?.sumInsured
                              )
                            }}</span>
                          </li>
                          <li>
                            <h6>Members Insured</h6>
                            {{ noOfAdult }} {{ (noOfAdult > 1) ? 'Adults' : 'Adult' }}
                            <template v-if="noOfChild > 0">  &amp; {{ noOfChild }} {{ (noOfChild > 1) ? 'Children' : 'Child'}}</template>
                          </li>
                          <li>
                            <h6>Policy Tenure</h6>
                            <span>
                              <!--{{ selectedPlan?.productDetails?.policyTermUnit }}-->
                              {{ selectedPlan.productDetails.premiumForEachPolicyTerm[selectedPolicyIndex] && selectedPlan.productDetails.premiumForEachPolicyTerm[selectedPolicyIndex].policyTerm ? selectedPlan.productDetails.premiumForEachPolicyTerm[selectedPolicyIndex].policyTerm : ""}}
                              Year
                            </span>
                          </li>
                          <!-- <li>
                            <h6>Payment Frequency</h6>
                            <span>{{ selectedPlan?.productDetails?.policyTermUnit }}
                              Yearly</span>
                          </li> -->
                          <li>
                            <h6>Net Premium</h6>
                            <span><em class="rupee">&#x20B9;</em> 
                              <!-- {{
                                currency(
                                  selectedPlan.productDetails
                                    .premiumForEachPolicyTerm[selectedPolicyIndex]
                                    .premiumForEachPPO[0].premiumForEachPPF[0]
                                    .premiumValue
                                )
                              }} -->
                              {{
                                currency(
                                  selectedPlan.totalPremiumDetails[selectedPolicyIndex]
                                    .totalPremiumValue
                                )
                              }}
                            </span>
                          </li>
                          <!-- <li>
                              <h6>Addons</h6>
                              <span>1,430</span>
                          </li> -->
                          <li>
                            <h6>GST @ 18%</h6>
                            <span> <em class="rupee">&#x20B9;</em> 
                              <!-- {{
                                currency(
                                  selectedPlan.productDetails
                                    .premiumForEachPolicyTerm[selectedPolicyIndex]
                                    .premiumForEachPPO[0].premiumForEachPPF[0]
                                    .totalTax
                                )
                              }} -->
                              {{
                                currency(
                                  selectedPlan.totalPremiumDetails[selectedPolicyIndex]
                                    .totalTax
                                )
                              }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <ul class="ttl-preminum poposer-footer">
                      <li><b>Total Premium</b></li>
                      <span>&#8377;
                        <!-- {{
                          currency(
                            selectedPlan.productDetails.premiumForEachPolicyTerm[
                              selectedPolicyIndex
                            ].premiumForEachPPO[0].premiumForEachPPF[0]
                              .totalPremiumValue
                          )
                        }} -->
                        {{
                          currency(
                            selectedPlan.totalPremiumDetails[selectedPolicyIndex]
                              .totalPremiumWithTax
                          )
                        }}
                      </span>
                    </ul>
                  </div>
                </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <!-- Disclaimer Section -->
    <Disclaimer :page="'CKYC'"/>
    <!--.\ Disclaimer Section -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import loadingPage from "@/components/loading.vue";
import Disclaimer from "@/components/disclaimer.vue";
import $ from "jquery";
export default {
  name: "Ckyc",
  props: {

  },
  components: {
    Header,
    loadingPage,
    Disclaimer
  },
  mounted() {
    console.log('store data: ', this.$store.state.configData);
    this.configData = this.$store.state.configData;

    let sessionData = sessionStorage.getItem("userProfile");
    if(sessionData != null)
    {
      this.userData=JSON.parse(sessionData)
    }

    const customerInfo = sessionStorage.getItem("customerInfo") ? JSON.parse(sessionStorage.getItem("customerInfo")) : null;
    if (customerInfo !== null) {
      this.panNumber = customerInfo?.customer_info?.kycDetails?.panNumber;
      this.dateOfBirth = customerInfo?.customer_info?.personalInformation?.dateOfBirth;
    }
    sessionStorage.setItem("request_json_flag_proposal", JSON.stringify(1));
    
    this.noOfAdult = 0;
    this.noOfChild = 0;
    for (let i = 0; i < this.userData.memberList.length; i++) {
      if (this.userData.memberList[i].member == 'Son' || this.userData.memberList[i].member == 'Daughter') {
        this.noOfChild++;
      } else {
        this.noOfAdult++;
      }
    }

    // Removing session data
    if(sessionStorage.getItem('filledData')) {
      sessionStorage.removeItem('filledData');
      sessionStorage.removeItem('currentTab');
      sessionStorage.removeItem('proposal_ui_id');
      sessionStorage.removeItem('filledDataTab');
    }
    if(sessionStorage.getItem('resumeProfile')){
      sessionStorage.removeItem('resumeProfile');
    }
    if(localStorage.removeItem("resumeProfile")){
      localStorage.removeItem("resumeProfile");
    }
    this.requestJson = JSON.parse(sessionStorage.getItem("request_json"));
    this.selectedPlan = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    this.quoteResponseId = JSON.parse(sessionStorage.getItem("quote_response_id"));

    console.log('this.requestJson: ', this.requestJson);
    console.log('this.selectedPlan: ', this.selectedPlan);
    if(this.requestJson.product && this.requestJson.product.policyTerm){
      this.selectedPolicyIndex = this.getSelectedPolicyIndex(this.requestJson.product.policyTerm);
    }

    this.channel_type = JSON.parse(sessionStorage.getItem("channel_type"));

    if (sessionStorage.getItem("rm_data")) {
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
      let sessionData = sessionStorage.getItem("userProfile");
      if(sessionData != null)
      {
        this.userData=JSON.parse(sessionData)
      }
    }
    this.getProposal();

    setTimeout(function(){
      console.log("focus");
      const el = document.getElementById('panNumber');
      if (el) { 
        el.focus();
      }
    },1500);
  },
  data() {
    return {
      loading: false,
      configData: "",
      rmData: "",
      requestJson: "",
      proposalJson: "",
      selectedPlan: "",
      noOfAdult: 0,
      noOfChild: 0,
      userData:{},
      userData: "",
      channel_type: "",
      panNumber: "",
      dateOfBirth: "",
      quoteResponseId: "",
      kycResponse: "",
      kycStatus: "",
      kycRedirectUrl: "",
      errorMsg: [],
      msg: [],
      selectedPolicyIndex: "",
      apiCallCounter: 0,
      journey_id : sessionStorage.getItem("journey_id") ? JSON.parse(sessionStorage.getItem("journey_id")) : "",
      isUploadDocRequired: false,
    };
  },
  methods: {
    getStatusWiseMsg(sts) {
      let msg = ""
      if(sts == 'Incomplete') {
        msg = 'KYC is not completed, Please try after some time.';
      } else if(sts == 'POAIncomplete') {
        msg = 'Proof of address verification failed. Please proceed with OVD flow.';
      }
      return msg;
    },
    getProposal() {
      this.loading = true;
      var self = this;
      let reqData = {
        "productId": this.selectedPlan.insuranceAndProducts.productCode,
        "manufacturerId": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,
        //"planId": this.selectedPlan.productDetails.planOption.planId,
        "version": 1
      };
      // let reqData = {
      //   productId: "2825",
      //   manufacturerId: "HDFCERGO",
      //   version: 1,
      // };
      let headers = {};

      this.backendApi("getProposal", reqData, headers, "get")
        .then((response) => {
          self.loading = false;

          console.log('self.loading: ', self.loading);

          console.log("proposal response: ", response);

          if (response.status == "error") {

            let msg = [response.data.detail];
            this.$router.push({ path: '/networkalert', query: { backUrl: 'proposer-page', msg: msg } });

          } else if (response.data.errors.length > 0) {

            let msg = [];
            for (let i = 0; i < response.data.errors.length; i++) {
              msg.push(response.data.errors[i]["errorIdentifier"]);
            }
            this.$router.push({ path: '/networkalert', query: { backUrl: 'proposal-page', msgError: "We are facing some issue while fetching proposal data", reqId: response.data.reqId, msg: msg } });

          } else {
            this.proposalJson = response.data.data;
            // setTimeout(function(){
            //   const el = document.getElementById('panNumber');
            //   el.focus();
            // },1000);
            
            
          }
        })
        .catch((error) => {
          console.log("error msg block : get proposal", error);
          self.loading = false;
          // self.apiError = true;
          //   let msg=['We are facing some issue from while fetching quotation API']

          //   this.$router.push({ path: '/networkalert', query: {backUrl:'policy-quote', msg: msg }});
        });
    },
    calculateAge(dob) {
      const date = new Date();
      var current_year = date.toLocaleString("default", { year: "numeric" });
      var current_month = date.toLocaleString("default", { month: "2-digit" });
      var current_day = date.toLocaleString("default", { day: "2-digit" });
      const dt = new Date(dob)
      var dob_year = dt.toLocaleString("default", { year: "numeric" });
      var dob_month = dt.toLocaleString("default", { month: "2-digit" });
      var dob_day = dt.toLocaleString("default", { day: "2-digit" });

      let new_age=current_year-dob_year;
      if(current_month<dob_month)
      new_age = new_age-1;
      else if(current_month==dob_month)
      {
        if(current_day<dob_day)
        new_age = new_age-1;
      }
      return new_age;
    },
    fetchKyc() {
      console.log('fetchKyc called: ', this.panNumber, 'DOB: ', this.dateOfBirth);
      let new_age = this.calculateAge(this.dateOfBirth)
      this.errorMsg = [];
      let regpan =  new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);
      //let regpan = new RegExp(/^([a-zA-Z]){4}([pP]){1}([0-9]){4}([a-zA-Z]){1}?$/);


      // (!this.panNumber) ?  this.errorMsg["pan_number"] = "Please enter pan card number" : (regpan.test(this.panNumber)) ? this.getCkycDetails() :  this.errorMsg["pan_number"] = "Invalid pan card number";
      // (!this.dateOfBirth) ? this.errorMsg["dob"] = "Please enter date of birth" : (new_age<18) ? this.errorMsg["dob"] = "For CKYC Proposer age to be greater than 18 year" : "";
      if(!this.panNumber) {
        this.errorMsg["pan_number"] = "Please enter pan card number";
      } else if(!regpan.test(this.panNumber)) {
        this.errorMsg["pan_number"] = "Invalid pan card number";
      } else if(!this.dateOfBirth) {
        this.errorMsg["dob"] = "Please enter date of birth";
      }
      else if(new_age<18)
      {
        this.errorMsg["dob"] = "For CKYC Proposer age to be greater than 18 year"; 
      } else {
        // valid pan card number
        this.getCkycDetails();
      }
      
    },
    
    
    getCkycDetails() {
      var self = this;
      this.loading = true;
      this.kycRedirectUrl = "";
      this.isUploadDocRequired = false;
      this.msg = [];

      let reqData = {
        "personalInformation": {
          "dateOfBirth": this.dateOfBirth,
          "email": this.userData.contactDetails.emailId,
          "mobileNumber": this.userData.contactDetails.mobileNumber,
          "zipCode": this.userData.zipCode,
          "pan": this.panNumber
        },
        "product": {
          "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,
          "productId": ""
        },
        "trackInfo": {
          "manufacturerQuoteID": this.getManuQuoteID(this.selectedPlan.insuranceAndProducts.insuranceCompanyCode),
          "manuApplicationNo": this.getManuApplicationNo(this.selectedPlan.insuranceAndProducts.insuranceCompanyCode),
          "KYCRequestID": this.quoteResponseId
        },
        "distributor": {
          "distributorID": this.configData["CONSUMER"]["consumerId"],
          "agentID": "agentId",
          "salesChannel": "online",
          "channelType": this.channel_type
        },
        "kycInfo": {
          "ReturnURL": "https://www.1silvebullet.tech"  
        },
        "journey_id": this.journey_id,
      };

      // Save request json  
      //sessionStorage.setItem("request_json", JSON.stringify(reqData));
      let headers = {};
      console.log('calling kyc...')
      this.backendApi("getCkyc", reqData, headers)
        .then((response) => {
          this.loading = false;
          this.kycResponse = response?.data?.data;
          this.kycStatus = this.kycResponse?.kycInfo?.Status;

          if(response.data.errors.length > 0) {
            for(let i=0; i<response.data.errors.length; i++)
            {
              this.msg.push(response.data.errors[i]["errorDisplayMessage"]);
            } 
          } else if(this.kycStatus == "approved") {
            sessionStorage.setItem("kyc_response", JSON.stringify(this.kycResponse));
            setTimeout(function() { 
              self.$router.push("proposer-details");
            }, 2000);
            
          } else if(response.data.data.kycInfo.Status == null && response.data.data.kycInfo.RedirectURL == null && this.selectedPlan.insuranceAndProducts.insuranceCompanyCode == 'ABHI') {
            
            this.getCkycDetails2();

          } else if((this.kycStatus == "Pending" || this.kycStatus == "POAIncomplete" || this.kycStatus == "Incomplete") && this.selectedPlan.insuranceAndProducts.insuranceCompanyCode == "BAGIC") {
            this.isUploadDocRequired = true;
          } else if(this.kycStatus == "Pending") {
            sessionStorage.removeItem('kyc_response');
            this.kycRedirectUrl = this.kycResponse.kycInfo.RedirectURL;
            setTimeout(function() { 
              window.open(self.kycRedirectUrl, '_blank').focus();
            }, 2000);
          }

        })
        .catch((error) => {
          console.log('error msg block : get kyc', error);
          self.loading = false;
        }); 
    },
    getCkycDetails2() {
      var self = this;
      this.loading = true;
      this.kycRedirectUrl = "";
      this.msg = [];

      let reqData = {
        "personalInformation": {
          "dateOfBirth": this.dateOfBirth,
          "email": this.userData.contactDetails.emailId,
          "mobileNumber": this.userData.contactDetails.mobileNumber,
          "zipCode": this.userData.zipCode,
          "pan": this.panNumber
        },
        "product": {
          "insuranceCompanyCode": this.selectedPlan.insuranceAndProducts.insuranceCompanyCode,
          "productId": ""
        },
        "trackInfo": {
          "manufacturerQuoteID": this.getManuQuoteID(this.selectedPlan.insuranceAndProducts.insuranceCompanyCode),
          "manuApplicationNo": this.quoteResponseId,
          "KYCRequestID": this.quoteResponseId
        },
        "distributor": {
          "distributorID": this.configData["CONSUMER"]["consumerId"],
          "agentID": "agentId",
          "salesChannel": "online",
          "channelType": this.channel_type
        },
        "kycInfo": {
          "ReturnURL": "https://www.1silvebullet.tech"  
        }
      };

      // Save request json  
      //sessionStorage.setItem("request_json", JSON.stringify(reqData));
      let headers = {};

      this.backendApi("getCkyc", reqData, headers)
        .then((response) => {
          this.loading = false;
          this.kycResponse = response.data.data;
          this.kycStatus = this.kycResponse.kycInfo.Status;
          this.apiCallCounter++;
          if(response.data.errors.length > 0) {
            for(let i=0; i<response.data.errors.length; i++)
            {
              this.msg.push(response.data.errors[i]["errorDisplayMessage"]);
            } 
          } else if(this.kycStatus == "approved") {
            sessionStorage.setItem("kyc_response", JSON.stringify(this.kycResponse));
            setTimeout(function() { 
              self.$router.push("proposer-details");
            }, 2000);
            
          } else {
            sessionStorage.removeItem('kyc_response');
            this.kycRedirectUrl = this.kycResponse.kycInfo.RedirectURL;
            setTimeout(function() { 
              window.open(self.kycRedirectUrl, '_blank').focus();
            }, 2000);
          }

        })
        .catch((error) => {
          console.log('error msg block : get kyc', error);
          self.loading = false;
        });
    },
    dateFormat(inputDate) {
      var date = new Date(inputDate);
      if (!isNaN(date.getTime())) {
        let d = (date.getMonth() + 1);
        if(d <= 9){
          d = '0'+ d;
        }
        return date.getDate() + '-' + d + '-' + date.getFullYear();
      }
    },
    checkArray(data) {
      return Array.isArray(data);
    },
    currency(amt) {
      // const tmpAmt = parseFloat(amt).toFixed(2);
      // return new Intl.NumberFormat().format(tmpAmt);
      const tmpAmt = parseFloat(amt).toFixed(Math.max(((amt+'').split(".")[1]||"").length, 2));
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getSelectedPolicyIndex(pTerm) {
      let itemIndex = this.selectedPlan.totalPremiumDetails.findIndex(item => item.policyTerm == pTerm);
      if(itemIndex > -1) {
        return itemIndex;
      }
    },
    getManuQuoteID(productCode) {
      if(productCode == 'HDFCERGO') {
        return this.selectedPlan.trackInfo.manufacturerQuoteID;
      } else if(productCode == 'NBHI') {
        return (this.selectedPlan.trackInfo.manufacturerQuoteID)?this.selectedPlan.trackInfo.manufacturerQuoteID:this.quoteResponseId;
      } else if(productCode == 'ABHI') {
        return this.selectedPlan.trackInfo.manufacturerQuoteID;
      } else {
        return this.selectedPlan.trackInfo.manufacturerQuoteID;
      }
    },
    getManuApplicationNo(productCode) {
      if(productCode == 'HDFCERGO') {
        return this.selectedPlan.trackInfo.manufacturerQuoteID;
      } else if(productCode == 'NBHI') {
        return (this.selectedPlan.trackInfo.manufacturerQuoteID)?this.selectedPlan.trackInfo.manufacturerQuoteID:this.quoteResponseId;
      } else if(productCode == 'ABHI') {
        //return this.selectedPlan.trackInfo.manufacturerQuoteID;
        // 4 character of PAN
        // 4 character from DOB DDMM
        // 4 Character from Today date DDMM
        //----------------------------------
       
        // Getting 4 Characters of PAN
        let first4Char = this.panNumber.substring(0, 4);

        // Getting DD and MM grom DOB
        const dtArr = this.dateOfBirth.split("-");

        // Getting Date and Month
        const d = new Date();
        let day = d.getDate();
        let month = d.getMonth();
        month += 1;
        console.log('month: ', month)
        if(month < 10){
          month = '0'+month;
        }

        let appCode = first4Char+dtArr[2]+dtArr[1]+day+month;
        //console.log('getManuApplicationNo called, appCode: ', appCode);

        return appCode;

      } else {
        return this.selectedPlan.trackInfo.manufacturerQuoteID;
      }
    },
    convertInLakh(amt) {
      if (amt < 10000000) {
        return amt / 100000 + " Lac";
      } else {
        return amt / 10000000 + " Cr.";
      }
    },
    uploadDocuments() {
      console.log('uploadDocuments called');
    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>