<template>
  <div class="addonrider">
    <AddonRiderPage />
  </div>
</template>

<script>
// @ is an alias to /src
import AddonRiderPage from "@/components/AddonRiderPage.vue";

export default {
  name: "AddonRiderView",
  components: {
    AddonRiderPage,
  },
};
</script>
