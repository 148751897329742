<template>
  <div class="Compare-plan-page">
    <ComparePlanpage />
  </div>
</template>

<script>
// @ is an alias to /src
import ComparePlanpage from "@/components/ComparePlanpage.vue";

export default {
  name: "ComparePlanView",
  components: {
    ComparePlanpage,
  },
};
</script>
