const Constants = {
    LANDING_PAGE_CONFIG: {"requirementPlan":["Comprehensive Plans","Top Up Plans","All Plans"],"genderList":["Male","Female"],"memberOptions":["Self","Spouse","Son","Daughter","Father","Mother","Father-in-law","Mother-in-law"], "singleSelectMember":["Self","Spouse","Father","Mother","Father-in-law","Mother-in-law"] ,"preExistingDiseaseOptions":[{"sectionName":"Existing illness","options":{"Blood Pressure":false,"Asthama":false,"Diabetes":false,"Thyroid":false,"Heart Conditions":false,"Cancer":false,"Other Diseases": false}},{"sectionName":"Surgical procedure","options":{"Appendix":false,"Gall bladder":false,"C-section":false,"Other Surgery": false}}],"incomeBrackets":["Upto 2.5 L", "2.5 L - 5 L", "5 L - 10 L", "Above 10 L"]},
    CONSUMER: {"consumerId":"ARKAFIN", "distributorLogo": "https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/Arka_Fincap_Logo.png", "contactEmail": "customercare@arkafincap.com", "infoEmail": "customercare@arkafincap.com", "contactNumber": "022-48901424","loginUrlB2C": "https://demo.health.insurance.1silverbullet.tech","loginUrlB2B": "https://demo.health.insurance.1silverbullet.tech","api_encryption": false, },
    ADDONS_MASTER: {
        10005: [{
            optionSelected: "safGuard",
            optionName: "Safeguard Benefit",
            optionDesc: "Helps in getting claim of non-payable items, no impact on NCB if the claim is up to 50,000, and guaranteed CPI linked increase in Base SI.",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "1",
            values: [{
                text: "Safeguard",
                value: "1"
            }, {
                text: "Safeguard Plus",
                value: "2"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "hcb",
            optionName: "Hospital Cash",
            optionDesc: "The add-on pays hospital cash for up to 30 days of hospitalisation if hospitalised for more than 48 hours​",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "1",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "MedPrac",
            optionName: "Medical Practitioner Discount",
            optionDesc: "Get 5% discount if any of the insured person is a medical practitioner",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "1",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "PACover",
            optionName: "Personal Accident Cover",
            optionDesc: "Personal Accident Cover 5 times the Sum Insured",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "1",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "disMgmt",
            optionName: "Disease Management",
            optionDesc: "Get day 1 coverage for Diabetes and Hypertension. Along with up to 20% renewal discount",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "G",
            values: [{
                text: "Gold",
                value: "G"
            }, {
                text: "Platinum",
                value: "P"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "acuteCar",
            optionName: "Acute Care",
            optionDesc: "Get unlimited tele-consultations with doctors (general, specialists & super specialists) of Apollo 24X7. Claim the entire cover amount for buying prescribed medicines\nClaim the entire cover amount for prescribed diagnostic tests",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "0",
            values: [{
                text: "Best Consult",
                value: "0"
            }, {
                text: "Best Care - 5000",
                value: "5000"
            }, {
                text: "Best Care - 10000",
                value: "10000"
            }, {
                text: "Best Care - 15000",
                value: "15000"
            }, {
                text: "Best Care - 20000",
                value: "20000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "deduc",
            optionName: "Deductible",
            optionDesc: "Deductible is an amount the insured chooses that they would like to pay from their pocket as and when a claim arises.If the insured goes for a higher deductible, they will be required to pay a lesser premium, and vice versa. ",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "10000",
            values: [{
                text: "10,000",
                value: "10000"
            }, {
                text: "20,000",
                value: "20000"
            }, {
                text: "30,000",
                value: "30000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }],
        10010: [{
            optionSelected: "safGuard",
            optionName: "Safeguard Benefit",
            optionDesc: "Helps in getting claim of non-payable items, no impact on NCB if the claim is up to 50,000, and guaranteed CPI linked increase in Base SI.",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "1",
            values: [{
                text: "Safeguard",
                value: "1"
            }, {
                text: "Safeguard Plus",
                value: "2"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "hcb",
            optionName: "Hospital Cash",
            optionDesc: "The add-on pays hospital cash for up to 30 days of hospitalisation if hospitalised for more than 48 hours​",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "1",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "MedPrac",
            optionName: "Medical Practitioner Discount",
            optionDesc: "Get 5% discount if any of the insured person is a medical practitioner",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "1",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "PACover",
            optionName: "Personal Accident Cover",
            optionDesc: "Personal Accident Cover 5 times the Sum Insured",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "1",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "disMgmt",
            optionName: "Disease Management",
            optionDesc: "Get day 1 coverage for Diabetes and Hypertension. Along with up to 20% renewal discount",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "G",
            values: [{
                text: "Gold",
                value: "G"
            }, {
                text: "Platinum",
                value: "P"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "acuteCar",
            optionName: "Acute Care",
            optionDesc: "Get unlimited tele-consultations with doctors (general, specialists & super specialists) of Apollo 24X7. Claim the entire cover amount for buying prescribed medicines\nClaim the entire cover amount for prescribed diagnostic tests",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "0",
            values: [{
                text: "Best Consult",
                value: "0"
            }, {
                text: "Best Care - 5000",
                value: "5000"
            }, {
                text: "Best Care - 10000",
                value: "10000"
            }, {
                text: "Best Care - 15000",
                value: "15000"
            }, {
                text: "Best Care - 20000",
                value: "20000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "deduc",
            optionName: "Deductible",
            optionDesc: "Deductible is an amount the insured chooses that they would like to pay from their pocket as and when a claim arises.If the insured goes for a higher deductible, they will be required to pay a lesser premium, and vice versa. ",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "10000",
            values: [{
                text: "10,000",
                value: "10000"
            }, {
                text: "20,000",
                value: "20000"
            }, {
                text: "30,000",
                value: "30000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }],
        10019: [{
            optionSelected: "safGuard",
            optionName: "Safeguard Benefit",
            optionDesc: "Helps in getting claim of non-payable items, no impact on NCB if the claim is up to 50,000, and guaranteed CPI linked increase in Base SI.",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "1",
            values: [{
                text: "Safeguard",
                value: "1"
            }, {
                text: "Safeguard Plus",
                value: "2"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "hcb",
            optionName: "Hospital Cash",
            optionDesc: "The add-on pays hospital cash for up to 30 days of hospitalisation if hospitalised for more than 48 hours​",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "1",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "MedPrac",
            optionName: "Medical Practitioner Discount",
            optionDesc: "Get 5% discount if any of the insured person is a medical practitioner",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "1",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "PACover",
            optionName: "Personal Accident Cover",
            optionDesc: "Personal Accident Cover 5 times the Sum Insured",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "1",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "disMgmt",
            optionName: "Disease Management",
            optionDesc: "Get day 1 coverage for Diabetes and Hypertension. Along with up to 20% renewal discount",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "G",
            values: [{
                text: "Gold",
                value: "G"
            }, {
                text: "Platinum",
                value: "P"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "acuteCar",
            optionName: "Acute Care",
            optionDesc: "Get unlimited tele-consultations with doctors (general, specialists & super specialists) of Apollo 24X7. Claim the entire cover amount for buying prescribed medicines\nClaim the entire cover amount for prescribed diagnostic tests",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "0",
            values: [{
                text: "Best Consult",
                value: "0"
            }, {
                text: "Best Care - 5000",
                value: "5000"
            }, {
                text: "Best Care - 10000",
                value: "10000"
            }, {
                text: "Best Care - 15000",
                value: "15000"
            }, {
                text: "Best Care - 20000",
                value: "20000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }, {
            optionSelected: "deduc",
            optionName: "Deductible",
            optionDesc: "Deductible is an amount the insured chooses that they would like to pay from their pocket as and when a claim arises.If the insured goes for a higher deductible, they will be required to pay a lesser premium, and vice versa. ",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "none",
            default: "10000",
            values: [{
                text: "10,000",
                value: "10000"
            }, {
                text: "20,000",
                value: "20000"
            }, {
                text: "30,000",
                value: "30000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get premium"
        }],
        10007: [{
            optionSelected: "ciCov",
            optionName: "Critical Illness Cover",
            optionDesc: "CI Cover of 3,5,10,15,20 Lacs. Survival Period is 15 Days",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "1000000",
            values: [{
                text: "3 Lac",
                value: "300000"
            }, {
                text: "5 Lac",
                value: "500000"
            }, {
                text: "10 Lac",
                value: "1000000"
            }, {
                text: "15 Lac",
                value: "1500000"
            }, {
                text: "20 Lac",
                value: "2000000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "hcb",
            optionName: "Hospital Cash Benefit",
            optionDesc: "This plan empowers policyholders to receive a daily cash allowance for each day of hospitalisation without taking the hospital bills into account.",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "5000",
            values: [{
                text: "INR 500",
                value: "500"
            }, {
                text: "INR 1000",
                value: "1000"
            }, {
                text: "INR 1500",
                value: "1500"
            }, {
                text: "INR 2000",
                value: "2000"
            }, {
                text: "INR 2500",
                value: "2500"
            }, {
                text: "INR 3000",
                value: "3000"
            }, {
                text: "INR 3500",
                value: "3500"
            }, {
                text: "INR 4000",
                value: "4000"
            }, {
                text: "INR 4500",
                value: "4500"
            }, {
                text: "INR 5000",
                value: "5000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "opdExp",
            optionName: "OPD Expenses",
            optionDesc: "OPD Expenses Covered upto specified limits",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "10000",
            values: [{
                text: "INR 5000",
                value: "5000"
            }, {
                text: "INR 6000",
                value: "6000"
            }, {
                text: "INR 7000",
                value: "7000"
            }, {
                text: "INR 8000",
                value: "8000"
            }, {
                text: "INR 9000",
                value: "9000"
            }, {
                text: "INR 10000",
                value: "10000"
            }, {
                text: "INR 11000",
                value: "11000"
            }, {
                text: "INR 12000",
                value: "12000"
            }, {
                text: "INR 13000",
                value: "13000"
            }, {
                text: "INR 14000",
                value: "14000"
            }, {
                text: "INR 15000",
                value: "15000"
            }, {
                text: "INR 16000",
                value: "16000"
            }, {
                text: "INR 17000",
                value: "17000"
            }, {
                text: "INR 18000",
                value: "18000"
            }, {
                text: "INR 19000",
                value: "19000"
            }, {
                text: "INR 20000",
                value: "20000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "PACover",
            optionName: "Personal Accident Cover (AD, PTD)",
            optionDesc: "Personal Accident Cover available for 5,10,15,20,30 Lacs",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "500000",
            values: [{
                text: "5 Lacs",
                value: "500000"
            }, {
                text: "10 Lacs",
                value: "1000000"
            }, {
                text: "15 Lacs",
                value: "1500000"
            }, {
                text: "20 Lacs",
                value: "2000000"
            }, {
                text: "30 Lacs",
                value: "3000000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
            },
            {
            optionSelected: "roomType",
            optionName: "Room Category",
            optionDesc: "Room rent limit is the maximum amount per day cost allowed by the insurer. Related expenses, such as doctor consultation etc. are paid in proportion to the room rent limit. Shared Room is the most economical option.",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "Shared Room",
            values: [{
                text: "Shared Room",
                value: "Shared Room"
            }, {
                text: "Single Private A/c Room",
                value: "Single Private A/c Room"
            }, {
                text: "Any Room",
                value: "Any Room"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }
        ],
        10008: [{
            optionSelected: "ciCov",
            optionName: "Critical Illness Cover",
            optionDesc: "CI Cover of 3,5,10,15,20 Lacs. Survival Period is 15 Days",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "1000000",
            values: [{
                text: "3 Lac",
                value: "300000"
            }, {
                text: "5 Lac",
                value: "500000"
            }, {
                text: "10 Lac",
                value: "1000000"
            }, {
                text: "15 Lac",
                value: "1500000"
            }, {
                text: "20 Lac",
                value: "2000000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "hcb",
            optionName: "Hospital Cash Benefit",
            optionDesc: "This plan empowers policyholders to receive a daily cash allowance for each day of hospitalisation without taking the hospital bills into account.",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "5000",
            values: [{
                text: "INR 500",
                value: "500"
            }, {
                text: "INR 1000",
                value: "1000"
            }, {
                text: "INR 1500",
                value: "1500"
            }, {
                text: "INR 2000",
                value: "2000"
            }, {
                text: "INR 2500",
                value: "2500"
            }, {
                text: "INR 3000",
                value: "3000"
            }, {
                text: "INR 3500",
                value: "3500"
            }, {
                text: "INR 4000",
                value: "4000"
            }, {
                text: "INR 4500",
                value: "4500"
            }, {
                text: "INR 5000",
                value: "5000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "opdExp",
            optionName: "OPD Expenses",
            optionDesc: "OPD Expenses Covered upto specified limits",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "10000",
            values: [{
                text: "INR 5000",
                value: "5000"
            }, {
                text: "INR 6000",
                value: "6000"
            }, {
                text: "INR 7000",
                value: "7000"
            }, {
                text: "INR 8000",
                value: "8000"
            }, {
                text: "INR 9000",
                value: "9000"
            }, {
                text: "INR 10000",
                value: "10000"
            }, {
                text: "INR 11000",
                value: "11000"
            }, {
                text: "INR 12000",
                value: "12000"
            }, {
                text: "INR 13000",
                value: "13000"
            }, {
                text: "INR 14000",
                value: "14000"
            }, {
                text: "INR 15000",
                value: "15000"
            }, {
                text: "INR 16000",
                value: "16000"
            }, {
                text: "INR 17000",
                value: "17000"
            }, {
                text: "INR 18000",
                value: "18000"
            }, {
                text: "INR 19000",
                value: "19000"
            }, {
                text: "INR 20000",
                value: "20000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "PACover",
            optionName: "Personal Accident Cover (AD, PTD)",
            optionDesc: "Personal Accident Cover available for 5,10,15,20,30 Lacs",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "500000",
            values: [{
                text: "5 Lacs",
                value: "500000"
            }, {
                text: "10 Lacs",
                value: "1000000"
            }, {
                text: "15 Lacs",
                value: "1500000"
            }, {
                text: "20 Lacs",
                value: "2000000"
            }, {
                text: "30 Lacs",
                value: "3000000"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        },
        {
            optionSelected: "roomType",
            optionName: "Room Category",
            optionDesc: "Room rent limit is the maximum amount per day cost allowed by the insurer. Related expenses, such as doctor consultation etc. are paid in proportion to the room rent limit. Shared Room is the most economical option.",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "single-select",
            default: "Shared Room",
            values: [{
                text: "Shared Room",
                value: "Shared Room"
            }, {
                text: "Single Private A/c Room",
                value: "Single Private A/c Room"
            }, {
                text: "Any Room",
                value: "Any Room"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }
        ],
        10018: [{
            optionSelected: "intCovr",
            optionName: "WorldWide Cover",
            optionDesc: "Hospitalization expenses incurred abroad shall be paid up to sum insured. This benefit is available for Sum Insured of 25 Lakhs and above.",
            addOnSumAssured: null,
            inbuiltFeature: 1,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "",
            default: "",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Inbuilt for SI >= 25 Lacs"
        }, {
            optionSelected: "domAirAm",
            optionName: "Domestic Air Ambulance",
            optionDesc: "Air ambulance expenses shall also be covered.",
            addOnSumAssured: null,
            inbuiltFeature: 1,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "",
            default: "",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Inbuilt in plan"
        }, {
            optionSelected: "ciCov",
            optionName: "Critical Illness",
            optionDesc: "Lumpsum payment equal to base SI subject to a maximum of 50 lakhs",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "",
            default: "",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "PACover",
            optionName: "Personal Accident (Death + PTD+PPD)",
            optionDesc: "Payment of amount equal to SI in the unfortunate event of Accidental death or Permanent total disablement or permanent partial disablement resulting from an Accident",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "",
            default: "",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "siProt",
            optionName: "Sum Insured Protector",
            optionDesc: "Protection against rising inflation by linking the Sum Insured under the base plan to the Consumer Price index (CPI)",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "",
            default: "",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "clmProt",
            optionName: "Claim Protector",
            optionDesc: "Under Hospitalization, the non payable items' cost will also be paid",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 1,
            premiumDetails: [],
            type: "",
            default: "",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }],
        10020: [{
            optionSelected: "clmProt",
            optionName: "Claim Shield",
            optionDesc: "Coverage of expense related to 68 Non-payable items during Hospitalization",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 0,
            premiumDetails: [],
            type: "",
            default: "",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "hlthChk",
            optionName: "Annual Health Check-up",
            optionDesc: "Once for all Insured every policy year",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 0,
            premiumDetails: [],
            type: "",
            default: "",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "instCov",
            optionName: "Instant Cover",
            optionDesc: "Instanct cover for Hypertension, Diabetes, Hyperlipidemia or Asthma post initial wait period of 30 days.",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 0,
            premiumDetails: [],
            type: "",
            default: "",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "opdExp",
            optionName: "Care OPD",
            optionDesc: "OPD Expenses Covered as per the policy schedules",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 0,
            premiumDetails: [],
            type: "",
            default: "",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "wpMod",
            optionName: "Modification of PED Wait Period",
            optionDesc: "PED wait period will be modified to 1 or 2 or 3 years as opted.",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 0,
            premiumDetails: [],
            type: "",
            default: "1 Year",
            values: [{
                text: "1 Year",
                value: "1 Year"
            }, {
                text: "2 Year",
                value: "2 Year"
            }, {
                text: "3 Year",
                value: "3 Year"
            }],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Add to get price"
        }, {
            optionSelected: "supNCB",
            optionName: "Cumulative Bonus Super",
            optionDesc: "Upto 100 % of SI per year, Max up to 500% of Sum Insured and will not reduce in case of claims. Inbuilt for age group 0-45 and 76-99",
            addOnSumAssured: null,
            inbuiltFeature: 0,
            premiumIncludedFlag: 0,
            premiumDetails: [],
            type: "",
            default: "",
            values: [],
            min: null,
            max: null,
            multipleOf: null,
            temp_price: "Inbuilt for age group 0-45 and 76-99"
        }],
        10024:[
            {	
                optionSelected: "roomType",
                optionName: "Room Category",
                optionDesc: "Room rent limit is the maximum amount per day cost allowed by the insurer. Related expenses, such as doctor consultation etc. are paid in proportion to the room rent limit. Shared Room is the most economical option.",
                addOnSumAssured: null,
                inbuiltFeature: 0,
                premiumIncludedFlag: 1,
                premiumDetails: [],
                type: "none",
                default: "Shared Accommodation",
                values: [{
                    text: "All Room Categories Covered",
                    value: "All Room Categories Covered"
                }, {
                    text: "Shared Accommodation",
                    value: "Shared Accommodation"
                }  ],
                min: null,
                max: null,
                multipleOf: null,
                temp_price: "Add to get price"
            },
            {
                optionSelected: "PACover",
                optionName: "Personal Accident Cover (AD, PTD)",
                optionDesc: "Personal Accident Cover available for 5,10,15,20,30 Lacs",
                addOnSumAssured: null,
                inbuiltFeature: 0,
                premiumIncludedFlag: 1,
                premiumDetails: [],
                type: "single-select",
                default: "Yes",
                values: [],
                min: null,
                max: null,
                multipleOf: null,
                temp_price: "Add to get price"
            }
            ],
        10025:[
            {	
                optionSelected: "roomType",
                optionName: "Room Category",
                optionDesc: "Room rent limit is the maximum amount per day cost allowed by the insurer. Related expenses, such as doctor consultation etc. are paid in proportion to the room rent limit. Shared Room is the most economical option.",
                addOnSumAssured: null,
                inbuiltFeature: 0,
                premiumIncludedFlag: 1,
                premiumDetails: [],
                type: "none",
                default: "Shared Accommodation",
                values: [{
                    text: "All Room Categories Covered",
                    value: "All Room Categories Covered"
                }, {
                    text: "Shared Accommodation",
                    value: "Shared Accommodation"
                }  ],
                min: null,
                max: null,
                multipleOf: null,
                temp_price: "Add to get price"
            },
            {
                optionSelected: "PACover",
                optionName: "Personal Accident Cover (AD, PTD)",
                optionDesc: "Personal Accident Cover available for 5,10,15,20,30 Lacs",
                addOnSumAssured: null,
                inbuiltFeature: 0,
                premiumIncludedFlag: 1,
                premiumDetails: [],
                type: "single-select",
                default: "Yes",
                values: [],
                min: null,
                max: null,
                multipleOf: null,
                temp_price: "Add to get price"
            }
            ],
        10026:[
            {	
                optionSelected: "roomType",
                optionName: "Room Category",
                optionDesc: "Room rent limit is the maximum amount per day cost allowed by the insurer. Related expenses, such as doctor consultation etc. are paid in proportion to the room rent limit. Shared Room is the most economical option.",
                addOnSumAssured: null,
                inbuiltFeature: 0,
                premiumIncludedFlag: 1,
                premiumDetails: [],
                type: "none",
                default: "Shared Accommodation",
                values: [{
                    text: "All Room Categories Covered",
                    value: "All Room Categories Covered"
                }, {
                    text: "Shared Accommodation",
                    value: "Shared Accommodation"
                }  ],
                min: null,
                max: null,
                multipleOf: null,
                temp_price: "Add to get price"
            },
            {
                optionSelected: "PACover",
                optionName: "Personal Accident Cover (AD, PTD)",
                optionDesc: "Personal Accident Cover available for 5,10,15,20,30 Lacs",
                addOnSumAssured: null,
                inbuiltFeature: 0,
                premiumIncludedFlag: 1,
                premiumDetails: [],
                type: "single-select",
                default: "Yes",
                values: [],
                min: null,
                max: null,
                multipleOf: null,
                temp_price: "Add to get price"
            }
            ],
        10027:[		
                        {
                            optionSelected: "intCovr",
                            optionName: "International Coverage for major illnesses",
                            optionDesc: "International Coverage available of 3 Crore is inbuilt. Select this if you want 6 Crore Cover for this addon",
                            addOnSumAssured: null,
                            inbuiltFeature: 0,
                            premiumIncludedFlag: 1,
                            premiumDetails: [],
                            type: "none",
                            default: "Yes",
                            values: [],
                            min: null,
                            max: null,
                            multipleOf: null,
                            temp_price: "Add to get price"
                        },
                             {
                    "optionSelected": "deduc",
                    "optionName": "Deductible",
                    "optionDesc": "Deductible is an amount the insured chooses that they would like to pay from their pocket as and when a claim arises.If the insured goes for a higher deductible, they will be required to pay a lesser premium, and vice versa. ",
                    "addOnSumAssured": null,
                    "inbuiltFeature": 0,
                    "premiumIncludedFlag": 1,
                    "premiumDetails": [],
                    "type": "none",
                    "default": "100000",
                    "values": [
                        {
                            "text": "1,00,000",
                            "value": "100000"
                        },
                        {
                            "text": "2,00,000",
                            "value": "200000"
                        },
                        {
                            "text": "3,00,000",
                            "value": "300000"
                        },
                        {
                            "text": "5,00,000",
                            "value": "500000"
                        },
                        {
                            "text": "10,00,000",
                            "value": "1000000"
                        }
                    ],
                    "min": null,
                    "max": null,
                    "multipleOf": null,
                    "temp_price": "Add to get premium"
            }],
        10028: [
            {
                                optionSelected: "clmProt",
                                optionName: "Care Shield",
                                optionDesc: "Helps in getting claim of non-payable items, no impact on NCB if the claim is less than 25% of base Suminsured, and guaranteed CPI linked increase in Base SI.",
                                addOnSumAssured: null,
                                inbuiltFeature: 0,
                                premiumIncludedFlag: 0,
                                premiumDetails: [],
                                type: "",
                                default: "",
                                values: [],
                                min: null,
                                max: null,
                                multipleOf: null,
                                temp_price: "Add to get price"
            },
            {
                                optionSelected: "hlthChk",
                                optionName: "Annual Health Check-up",
                                optionDesc: "A set of predefined test will covered at no extra cost with netwrok hospital",
                                addOnSumAssured: null,
                                inbuiltFeature: 0,
                                premiumIncludedFlag: 0,
                                premiumDetails: [],
                                type: "",
                                default: "",
                                values: [],
                                min: null,
                                max: null,
                                multipleOf: null,
                                temp_price: "Add to get price"
            },
            {
                                optionSelected: "domAirAm",
                                optionName: "Air Ambulance",
                                optionDesc: "Upto 5 Lacs",
                                addOnSumAssured: null,
                                inbuiltFeature: 0,
                                premiumIncludedFlag: 0,
                                premiumDetails: [],
                                type: "",
                                default: "",
                                values: [],
                                min: null,
                                max: null,
                                multipleOf: null,
                                temp_price: "Add to get price"
            },
            {
                                optionSelected: "opdExp",
                                optionName: "Care OPD",
                                optionDesc: "OPD Expenses Covered as per the policy schedules",
                                addOnSumAssured: null,
                                inbuiltFeature: 0,
                                premiumIncludedFlag: 0,
                                premiumDetails: [],
                                type: "",
                                default: "",
                                values: [],
                                min: null,
                                max: null,
                                multipleOf: null,
                                temp_price: "Add to get price"
            },
            {
                                optionSelected: "wpMod",
                                optionName: "Reduction in PED",
                                optionDesc: "PED wait period will be reduced to 2 years.",
                                addOnSumAssured: null,
                                inbuiltFeature: 0,
                                premiumIncludedFlag: 0,
                                premiumDetails: [],
                                type: "",
                                default: "",
                                values: [],
                                min: null,
                                max: null,
                                multipleOf: null,
                                temp_price: "Add to get price"
            },
            {
                                optionSelected: "supNCB",
                                optionName: "NCB Super",
                                optionDesc: "Get your sum insured increased up to 150% in 5 consecutive claim free years",
                                addOnSumAssured: null,
                                inbuiltFeature: 0,
                                premiumIncludedFlag: 0,
                                premiumDetails: [],
                                type: "",
                                default: "",
                                values: [],
                                min: null,
                                max: null,
                                multipleOf: null,
                                temp_price: "Add to get price"
            },
            {
                                optionSelected: "ppnDisc",
                                optionName: "Smart Select",
                                optionDesc: "Smart Select will allow 15% Discount on Premium in lieu of making cashless claims in Smart Select Network Hospitals. 20% Co-Payment will be applicable on re-imbersement claims in Network or outside network",
                                addOnSumAssured: null,
                                inbuiltFeature: 0,
                                premiumIncludedFlag: 0,
                                premiumDetails: [],
                                type: "",
                                default: "",
                                values: [],
                                min: null,
                                max: null,
                                multipleOf: null,
                                temp_price: "Add to get price"
            },
            {
                                optionSelected: "RRM",
                                optionName: "Room Rent Modification",
                                optionDesc: "Room Rent Modification will restrict room eligibility to Most Economical Single Private Room",
                                addOnSumAssured: null,
                                inbuiltFeature: 0,
                                premiumIncludedFlag: 0,
                                premiumDetails: [],
                                type: "",
                                default: "",
                                values: [],
                                min: null,
                                max: null,
                                multipleOf: null,
                                temp_price: "Add to get price"
            },
            {
                                optionSelected: "intCovr",
                                optionName: "Global Coverage",
                                optionDesc: "Worldwide Coverage",
                                addOnSumAssured: null,
                                inbuiltFeature: 0,
                                premiumIncludedFlag: 0,
                                premiumDetails: [],
                                type: "single-select",
                                default: "WW Excl. US and Canada",
                                values: [
                                    {
                                        text: "World Wide Coverage excluding USA & Canada",
                                        value: "WW Excl. US and Canada"
                                    },
                                    {
                                        text: "World Wide Coverage",
                                        value: "Worldwide"
                                    }
                                ],
                                min: null,
                                max: null,
                                multipleOf: null,
                                temp_price: "Add to get price"
            }
        ],
        10029:[
            {	
                optionSelected: "deduc",
                optionName: "Deductible",
                optionDesc: "Get a discount on your insurance premium when you agree to pay a certain aggregate deductible amount.",
                addOnSumAssured: null,
                inbuiltFeature: 0,
                premiumIncludedFlag: 0,
                premiumDetails: [],
                type: "single-select",
                default: "25000",
                values: [{
                    text: "Rs 25000",
                    value: "25000"
                }, {
                    text: "Rs 50000",
                    value: "50000"
                },{
                    text: "Rs 100000",
                    value: "100000"
                },  ],
                min: null,
                max: null,
                multipleOf: null,
                temp_price: "Add to get price"
            } 
        ] 
        
    },
    UW_MASTER: {
      "ABHI": "no",
      "NBHI": "yes",
      "HDFCERGO": "no",
      "IL": "no"
    },
    AGE:{
      "Self": ["18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66","67","68","69","70","71","72","73","74","75","76","77","78","79","80","81","82","83","84","85","86","87","88","89","90","91","92","93","94","95","96","97","98","99"],
      "Spouse": ["18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66","67","68","69","70","71","72","73","74","75","76","77","78","79","80","81","82","83","84","85","86","87","88","89","90","91","92","93","94","95","96","97","98","99"],
      "Father": ["36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66","67","68","69","70","71","72","73","74","75","76","77","78","79","80","81","82","83","84","85","86","87","88","89","90","91","92","93","94","95","96","97","98","99"],
      "Mother": ["36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66","67","68","69","70","71","72","73","74","75","76","77","78","79","80","81","82","83","84","85","86","87","88","89","90","91","92","93","94","95","96","97","98","99"],
      "Father-in-law": ["36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66","67","68","69","70","71","72","73","74","75","76","77","78","79","80","81","82","83","84","85","86","87","88","89","90","91","92","93","94","95","96","97","98","99"],
      "Mother-in-law": ["36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66","67","68","69","70","71","72","73","74","75","76","77","78","79","80","81","82","83","84","85","86","87","88","89","90","91","92","93","94","95","96","97","98","99"],
      "Son":["0.92","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30"],
      "Daughter" :["0.92","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30"],
     
    },
    RE_CKYC:{
        "ABHI":["6212"],
        "NBHI": [],
        "HDFCERGO": [],
        "IL":[]
    },
    //LAZY_LOADING:{"inerval":"5000"},
    //POLICY_COVER_AMOUNT:[{"text":"500000","value":"500000"},{"text":"1000000","value":"1000000"},{"text":"1500000","value":"1500000"},{"text":"2000000","value":"2000000"},{"text":"2500000","value":"2500000"},{"text":"3000000","value":"3000000"},{"text":"3500000","value":"3500000"},{"text":"4000000","value":"4000000"}],
    //POLICY_BENEFIT:[{"benefit_name":"Recovery Benefit","option_code":"recvBen","option_description":"Recovery Benefit Available"},{"benefit_name":"Hospital Cash Benefit","option_code":"hcb","option_description":"Hospital Cash Benefit Available"},{"benefit_name":"Super NCB","option_code":"supNCB","option_description":"Extended/Super NCB available"},{"benefit_name":"International Emergency Assistance","option_code":"intEmerg","option_description":"International Emergency Assistance Available"},{"benefit_name":"Maternity Cover","option_code":"matExp","option_description":"Maternity Cover availability"},{"benefit_name":"OPD Expenses","option_code":"opdExp","option_description":"OPD Expenses Covered"},{"benefit_name":"Unlimited Reload of SI","option_code":"ulReload","option_description":"Unlimited Reload"},{"benefit_name":"Zero Copayment","option_code":"copay","option_description":"No Copayment"},{"benefit_name":"Copayment/Deductible","option_code":"deduc","option_description":"Amount deductible for claim or %age of copayment"}],
    //INSURER_LIST:[{"manufacturer_name":"HDFC Ergo","manufacturer_id":"HDFCERGO"},{"manufacturer_name":"Niva Bupa","manufacturer_id":"NBHI"},{"manufacturer_name":"Aditya Birla","manufacturer_id":"ABHI"}],
    //LOGOUT_URL_REDIRECTION: ["https://www.bajajcapitalinsurance.com/"],
    //DISTRIBUTER:{"distributerID":"BCIBL","distributorName":"BCIBL", "distributorLogo": "https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/BCIBL+logo.png", "contactEmail": "Contact@bajajcapital.com", "infoEmail": "info@bajajcapitalinsurance.com", "contactNumber": "1800-313-123123"},
    //FEATURED_PLAN_ORDER: [{"plan_id":"10004","order":"1"},{"plan_id":"10005","order":"2"},{"plan_id":"10007","order":"3"},{"plan_id":"10006","order":"4"},{"plan_id":"10003","order":"5"},{"plan_id":"10008","order":"6"},{"plan_id":"10002","order":"7"}],
    //PAY_SETTINGS: {"HDFCERGO":"PRE", "NBHI": "PRE", "ABHI":"PRE"},
    //GATE_CRITERIA_SETTINGS: {"HDFCERGO":"NO", "NBHI": "NO", "ABHI":"NO"},
    //VIEW_MORE_COUNTER: 4,
    
  };
  Object.freeze(Constants);
  export function getConsumer(){
    return Constants.CONSUMER;
  }
  export function getLandingPageConfig(){
    return Constants.LANDING_PAGE_CONFIG;
  }
  export function getAddOnsList() {
    return Constants.ADDONS_MASTER;
  }
  export function getUWList() {
    return Constants.UW_MASTER;
  }
  export function getage() {
    return Constants.AGE;
  }
  export function getReCKYC() {
    return Constants.RE_CKYC;
  }
  /*
  export function getLazyLoadingConfig(){
    return Constants.LAZY_LOADING;
  }
  export function getPolicyCoverAmt(){
    return Constants.POLICY_COVER_AMOUNT;
  }
  export function getPolicyBenefitList(){
    return Constants.POLICY_BENEFIT;
  }
  export function getInsurerList(){
    return Constants.INSURER_LIST;
  }
  export function getLogoutURL(){
    return Constants.LOGOUT_URL_REDIRECTION;
  }
  export function getDistributer(){
    return Constants.DISTRIBUTER;
  }
  export function getFeaturedPlanOrder() {
    return Constants.FEATURED_PLAN_ORDER;
  }
  export function getPaySettings() {
    return Constants.PAY_SETTINGS;
  }
  export function getGateCriteriaSettings() {
    return Constants.GATE_CRITERIA_SETTINGS;
  }*/