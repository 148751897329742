<template>
    <div>
        
        <UploadDoc />
    </div>
</template>

<script>
// @ is an alias to /src

import UploadDoc from "@/components/UploadDoc.vue";

export default {
  name:' UploadDocView',
  components: {
    
     UploadDoc
  }
}
</script>

<!-- <style scoped lang="scss">
@import '~@/assets/scss/components/uploaddoc.scss';
</style> -->